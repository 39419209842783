import * as React from 'react'

import sanitizeHtml from 'sanitize-html'

export default function Sanitize({ html, addMarkup = false }) {
  if (addMarkup) html = simpleMarkup(html)

  return (
    <div
      className="break-words"
      dangerouslySetInnerHTML={{ __html: removeLargeWords(sanitizeHtml(html)) }}
    />
  )
}

const removeLargeWords = (text) => {
  return text
    .split(' ')
    .filter((word) => word.length <= 40)
    .join(' ')
}

const simpleMarkup = (text) => {
  return text
    .replace(/\n/g, '<br />') // Replace newlines with <br /> tags
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Replace ** with <b> tags
    .replace(/\|\|\|/g, '<br />') // Replace ||| with <br /> tags
    .replace(/\|\|/g, '<br />') // Replace || with <br /> tags
}
