import React from 'react'
import { useSkills } from '../SkillsProvider'
import GraphView from './GraphView'
import { cn } from '../../../lib/utils'
import { ChevronUpIcon } from 'lucide-react'
import SkillsList from './SkillsList'
import SkillCard from './SkillCard'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../../../lib/ui/dialog'

const SkillsInventoryView = () => {
  const {
    graphViewActive,
    cardViewActive,
    selectedSkill,
    isMobile,
    desiredSkillsActive,
  } = useSkills()

  const selectedSkillClasses = isMobile
    ? 'cursor-pointer rounded-[10px] px-5 py-[10px] text-[#fff] selected'
    : ''
  return (
    <>
      {!isMobile && <SkillsList />}
      {isMobile && (
        <div
          className={cn(
            `col-span-2 checkbox-${selectedSkill[0]}`,
            selectedSkillClasses,
          )}
        >
          <div className="grid grid-cols-5">
            <div className="col-span-4">{selectedSkill[1]}</div>
            <ChevronUpIcon className="h-4 w-4" />
          </div>
        </div>
      )}

      {graphViewActive && <GraphView />}
      <ChooseSkillsDialog />
      {(cardViewActive || desiredSkillsActive) && <CardView />}
      {isMobile && <SkillsList />}
    </>
  )
}
export default SkillsInventoryView

const CardView = () => {
  return (
    <div className="mt-[0px] py-4 md:col-span-2 md:py-0">
      <SkillCard />
    </div>
  )
}

export const ChooseSkillsDialog = ({ children }) => {
  const {
    skillsDialogOpen,
    skillsForSkillsDialog,
    setSelectedSkill,
    openCardViewTab,
    toggleSkillsDialog,
  } = useSkills()
  const handleSkillClick = (skill) => {
    setSelectedSkill(skill)
    toggleSkillsDialog()
    openCardViewTab()
  }
  return (
    <Dialog open={skillsDialogOpen} onOpenChange={toggleSkillsDialog}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Choose Your Skill</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-8">
          {skillsForSkillsDialog.map((skill, idx) => (
            <div
              onClick={() => handleSkillClick(skill)}
              className="btn btn-primary"
              key={`choose-skill-${idx}`}
            >
              {skill[1]}
            </div>
          ))}
        </div>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
