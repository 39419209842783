import React, { createContext, useContext, useState } from 'react'
import { useIsMobile, useIsDesktop } from '../../hooks/useMediaQuery'

const PersonalBrandContext = createContext()

export const usePersonalBrand = () => {
  return useContext(PersonalBrandContext)
}
const PersonalBrandProvider = ({
  serialized_exercise_list,
  serialized_workshop_list,
  cover_letters,
  pitchDocument,
  pitchComplete,
  children,
  upload_path,
}) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const exercises = serialized_exercise_list
  const workshops = serialized_workshop_list
  const coverLetters = cover_letters
  const uploadPath = upload_path
  const pitch = pitchDocument
  const pitch_complete = pitchComplete

  const [personalBrandView, setPersonalBrandView] = useState('graph_view')

  const value = {
    exercises,
    workshops,
    pitch,
    pitch_complete,
    coverLetters,
    personalBrandView,
    setPersonalBrandView,
    isMobile,
    isDesktop,
    uploadPath,
  }

  return (
    <PersonalBrandContext.Provider value={value}>
      {children}
    </PersonalBrandContext.Provider>
  )
}

export default PersonalBrandProvider
