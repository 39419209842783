import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import axios from 'axios'
import { useState } from 'react'

export const useUploadVideoForm = ({ videoBlob }) => {
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const schema = {
    video_file: z.union([z.string(), z.instanceof(File), z.null()]).optional(),
  }

  const video = videoBlob
    ? new File([videoBlob], 'coach_profile_video.webm', {
        type: 'video/webm',
      })
    : null

  const defaultValues = { video_file: '' }

  const form = useForm({
    resolver: zodResolver(z.object(schema)),
    defaultValues,
  })

  const onSubmit = async (values) => {
    setSubmitted(true)
    const token = document.querySelector('meta[name="csrf-token"]').content
    const url = '/coach/submit_video'
    const data = {
      coach_profile: {
        video: video || values.video_file,
      },
    }
    const config = {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'multipart/form-data',
      },
    }

    const response = await axios.patch(url, data, config)

    if (response.status === 200) {
      setSuccess(true)
      window.location.reload()
    } else {
      console.log('error')
      console.log(response)
      console.log(response.error)
      setSuccess(false)
      window.location.reload()
      throw new Error('Error submitting video')
    }
  }

  return { form, onSubmit, submitted, success }
}
