import React from 'react'
import { cn } from '../../lib/utils'

export const AssessmentsIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  const strokeWidth = '4.5'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      viewBox="0 0 75 75"
      fill="none"
    >
      <title>Assessments Icon</title>
      <path
        d="M21.875 43.75C16.6973 43.75 12.5 47.9472 12.5 53.125C12.5 58.3028 16.6973 62.5 21.875 62.5C22.9707 62.5 24.0226 62.3119 25 61.9666"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3246 48.7646C9.13831 46.7446 6.25 42.4596 6.25 37.5003C6.25 33.7137 7.93353 30.3206 10.5929 28.0283"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6892 27.7756C9.85906 26.5347 9.375 25.0426 9.375 23.4375C9.375 19.1228 12.8728 15.625 17.1875 15.625C18.9466 15.625 20.5699 16.2064 21.8757 17.1875"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6178 17.3917C22.1414 16.3817 21.875 15.2532 21.875 14.0625C21.875 9.74778 25.3728 6.25 29.6875 6.25C34.0022 6.25 37.5 9.74778 37.5 14.0625V62.5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 62.5C25 65.9519 27.7982 68.75 31.25 68.75C34.7019 68.75 37.5 65.9519 37.5 62.5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5 21.875C37.5 27.0527 41.6972 31.25 46.875 31.25"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4067 28.0283C67.0661 30.3206 68.7499 33.7137 68.7499 37.5003C68.7499 39.6974 68.183 41.7621 67.1874 43.5562"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.3111 27.7756C65.1411 26.5347 65.6251 25.0426 65.6251 23.4375C65.6251 19.1228 62.1273 15.625 57.8126 15.625C56.0536 15.625 54.4301 16.2064 53.1245 17.1875"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5 14.0625C37.5 9.74778 40.9978 6.25 45.3125 6.25C49.6272 6.25 53.125 9.74778 53.125 14.0625C53.125 15.2532 52.8588 16.3817 52.3822 17.3917"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.75 68.75C40.2981 68.75 37.5 65.9519 37.5 62.5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.0625 64.0625L68.75 68.75"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 57.8125C50 62.1272 53.4978 65.625 57.8125 65.625C59.9738 65.625 61.9297 64.7475 63.3441 63.3294C64.7534 61.9162 65.625 59.9662 65.625 57.8125C65.625 53.4978 62.1272 50 57.8125 50C53.4978 50 50 53.4978 50 57.8125Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CheckMarkIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={cn('size-6', className)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.5 12.75 6 6 9-13.5"
      />
    </svg>
  )
}
