import * as React from 'react'
import useTranslation, {
  TranslationProvider,
} from '../shared/TranslationProvider'
import translations from './locales.json'
import { Solo } from './programs/Solo'
import { SoloPlus } from './programs/SoloPlus'
import { Flex } from './programs/Flex'
import { Premium } from './programs/Premium'
import { Select } from './programs/Select'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Reminders from './Reminders'
import { DashboardProvider, useDashboard } from './DashboardProvider'
import RemindersCard from './cards/RemindersCard'
import BenefitsProvider from '../coach_services/BenefitsProvider'
import { useMediaQueryScreen } from '../../hooks/useMediaQuery'

const Index = ({ ...props }) => {
  return (
    <DashboardProvider {...props}>
      <Dashboard {...props} />
    </DashboardProvider>
  )
}
export default Index

const randomize = (array) => {
  return array[Math.floor(Math.random() * array.length)]
}

const WelcomeMessage = ({ current_user }) => {
  const { t } = useTranslation()
  const { first_name, sign_in_count } = current_user

  const isMorning = new Date().getHours() < 12

  const welcome_message_first_login = t('welcome_message_first_login', {
    first_name: first_name,
  })

  const welcome_message_afternoon = randomize(
    t('welcome_message_afternoon', { first_name: first_name }),
  )
  const welcome_message_morning = t('welcome_message_morning', {
    first_name: first_name,
  })
  const welcome_message_returning_user = isMorning
    ? welcome_message_morning
    : welcome_message_afternoon

  const first_row_message =
    sign_in_count == 1
      ? welcome_message_first_login
      : welcome_message_returning_user

  const second_row_message = randomize(t('welcome_message_second_row'))

  return (
    <>
      <p className="mb-4 font-roboto-flex text-lg leading-7 text-[#4a5463]">
        {first_row_message}
      </p>
      <p className="-mb-4 font-roboto-flex text-lg leading-7 text-[#4a5463]">
        {second_row_message}
      </p>
    </>
  )
}

const Dashboard = ({
  current_user,
  calendar_events,
  tracker_progress,
  coach,
  tracker_time_period,
  applied_job_count,
  quote,
  recommended_jobs,
  recommended_blogs,
  general_recommendations,
  orientation_call_completed,
  time_since_last_check_in,
  coach_calendly_url,
  candidate_name,
  candidate_email,
  candidate_id,
  coach_id,
  calendly_events_path,
  completed_calendly_event,
  pending_event,
}) => {
  const queryClient = new QueryClient()
  const { onlyReminders } = useDashboard()
  const orientationPopupProps = {
    coach_calendly_url,
    candidate_name,
    candidate_email,
    candidate_id,
    coach_id,
    calendly_events_path,
    completed_calendly_event,
    pending_event,
  }
  const isLargeScreen = useMediaQueryScreen('lg')
  const inChatFrame = parent.document.getElementById('chat_browse') !== null

  return (
    <BenefitsProvider {...orientationPopupProps}>
      {onlyReminders && <Reminders />}
      {!onlyReminders && (
        <QueryClientProvider client={queryClient}>
          <TranslationProvider
            locale={current_user.locale}
            translations={translations}
            scope="javascript.components.home"
          >
            <div className="flex flex-col font-roboto text-cod-gray-800 lg:grid lg:grid-cols-12">
              <div
                className={`home-container m-4 flex flex-col gap-[32px] ${
                  inChatFrame ? 'lg:col-span-12' : 'lg:col-span-9'
                } lg:m-12`}
              >
                <div>
                  <h1 className="m-0 mb-4 p-0 font-roboto-slab text-3.5xl leading-tight text-primary">
                    My Dashboard
                  </h1>
                  <WelcomeMessage current_user={current_user} />
                </div>
                {!isLargeScreen && <RemindersCard />}
                {current_user.bronze && (
                  <Solo
                    tracker_progress={tracker_progress}
                    tracker_time_period={tracker_time_period}
                    applied_job_count={applied_job_count}
                    quote={quote}
                    recommended_jobs={recommended_jobs}
                    recommended_blogs={recommended_blogs}
                    general_recommendations={general_recommendations}
                  />
                )}
                {current_user.solo_plus && <SoloPlus />}
                {current_user.silver && (
                  <Flex
                    tracker_progress={tracker_progress}
                    tracker_time_period={tracker_time_period}
                    applied_job_count={applied_job_count}
                    coach={coach}
                    quote={quote}
                    recommended_jobs={recommended_jobs}
                    recommended_blogs={recommended_blogs}
                    calendar_events={calendar_events}
                    time_since_last_check_in={time_since_last_check_in}
                  />
                )}
                {current_user.gold && (
                  <Premium
                    tracker_progress={tracker_progress}
                    tracker_time_period={tracker_time_period}
                    coach={coach}
                    quote={quote}
                    recommended_jobs={recommended_jobs}
                    recommended_blogs={recommended_blogs}
                    calendar_events={calendar_events}
                    orientation_call_completed={orientation_call_completed}
                    current_user={current_user}
                  />
                )}
                {current_user.select && <Select />}
              </div>
              {isLargeScreen && !inChatFrame && <Reminders />}
            </div>
          </TranslationProvider>
        </QueryClientProvider>
      )}
    </BenefitsProvider>
  )
}
