import React, { useState } from 'react'
import ProgramEvaluationProvider, {
  useProgramEvaluation,
} from './ProgramEvaluationProvider'
import PreProgramEvaluation from './PreProgramEvaluation'
import PostProgramEvaluation from './PostProgramEvaluation'
import TopicSelection from './TopicSelection'
import { TranslationProvider } from '../../../shared/TranslationProvider'
import { cn } from '../../../../lib/utils'
import translations from '../../locales.json'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from '../../../../lib/ui/dialog'
import { Button } from '../../../../lib/ui/button'
import ProgramEvalHeader from './ProgramEvalHeader'
import MidProgramEvaluation from './MidProgramEvaluation'
import PostSessionEval from './PostSessionEval'
const ProgramEvaluationsInner = ({ modal, setShowButton }) => {
  const {
    preProgramEvaluation,
    topicSelection,
    candidate,
    outplatform,
    postProgramEvaluation,
    managerPostEval,
    peakPerformancePostEval,
    midProgramEval,
    postSessionEval,
  } = useProgramEvaluation()

  const whitebg = topicSelection || postProgramEvaluation || managerPostEval

  const bgClasses = whitebg ? 'bg-white' : 'bg-[#E0F2F9]'
  const borderClasses = outplatform ? 'border-t' : ''
  const modalClasses = cn(modal ? 'md:p-12 rounded-b-2xl' : 'md:px-48 md:py-20')

  const showPostProgramEvaluation =
    postProgramEvaluation || managerPostEval || peakPerformancePostEval

  return (
    <TranslationProvider
      locale={candidate.locale}
      translations={translations}
      scope={
        'javascript.components.CareerDevelopment.CoachingPrograms.ProgramEvaluations'
      }
    >
      <div
        className={cn(
          'flex flex-col gap-4 p-4 md:gap-8',
          bgClasses,
          borderClasses,
          modalClasses,
        )}
      >
        <ProgramEvalHeader />
        <div>
          {preProgramEvaluation && (
            <PreProgramEvaluation setShowButton={setShowButton} />
          )}
          {postSessionEval && <PostSessionEval />}
          {midProgramEval && <MidProgramEvaluation />}
          {showPostProgramEvaluation && <PostProgramEvaluation />}
          {topicSelection && <TopicSelection />}
        </div>
      </div>
    </TranslationProvider>
  )
}

const ProgramEvaluations = ({
  modal,
  topicSelection,
  postProgramEvaluation,
  managerPostEval,
  btnText,
  classes,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const toggleDialog = () => setOpenDialog(!openDialog)
  return (
    <ProgramEvaluationProvider {...props}>
      {modal && (
        <Dialog open={openDialog} onOpenChange={toggleDialog}>
          {showButton && (
            <DialogTrigger className="cursor-pointer" asChild>
              <Button className={classes}>
                {btnText || 'Start Pre-Coaching Evaluation'}
              </Button>
            </DialogTrigger>
          )}
          <DialogContent
            className={cn('h-[40rem] max-w-[90rem] bg-transparent md:h-[90vh]')}
            xClass="text-black"
          >
            <div className="overflow-scroll">
              <ProgramEvaluationsInner
                setShowButton={setShowButton}
                modal={true}
              />
            </div>
            <DialogClose />
          </DialogContent>
        </Dialog>
      )}
      {!modal && <ProgramEvaluationsInner modal={false} />}
    </ProgramEvaluationProvider>
  )
}

export default ProgramEvaluations
