import React from 'react'
import { cn } from '../../../lib/utils'
import CoverLettersList from './CoverLettersList'

const CoverLettersRightNav = ({ className }) => {
  return (
    <>
      <div
        className={cn(
          'reminders-div relative bg-primary p-4 text-white md:col-span-3 md:h-full',
          className,
        )}
      >
        <CoverLettersList />
      </div>
    </>
  )
}

export default CoverLettersRightNav
