import React, { useState } from 'react'
import useAssociatedBehaviors, {
  AssociatedBehaviorsProvider,
} from './AssociatedBehaviorsProvider'
import { Textarea } from '../../../lib/ui/textarea'
import { Label } from '../../../lib/ui/label'

const AssociatedBehaviors = ({ ...props }) => {
  return (
    <AssociatedBehaviorsProvider {...props}>
      <AssociatedBehaviorsInner />
    </AssociatedBehaviorsProvider>
  )
}

export default AssociatedBehaviors

const AssociatedBehaviorsInner = () => {
  const { selectedValues } = useAssociatedBehaviors()
  return (
    <div className="flex flex-col gap-6">
      {selectedValues.map((val, idx) => (
        <ValueInputTile key={`val-input-tile-${idx}`} value={val} />
      ))}
    </div>
  )
}

const ValueInputTile = ({ value }) => {
  const { state, dispatch, updateDocument, getValueName } =
    useAssociatedBehaviors()
  const valueName = getValueName(value)
  const [inputValue, setInputValue] = useState(state[value] || '')

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    dispatch({
      type: 'UPDATE_BEHAVIOR',
      payload: { name: value, content: e.target.value },
    })
  }
  return (
    <div>
      <Label className="font-roboto">
        Value: {valueName[0].toUpperCase() + valueName.slice(1)}
      </Label>
      <Textarea
        value={inputValue}
        onChange={handleInputChange}
        onBlur={updateDocument}
        className="font-roboto"
      />
    </div>
  )
}
