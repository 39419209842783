import React, { useEffect } from 'react'
import { useExercise } from './ExerciseProvider'
import { useTranslation } from '../../hooks/useTranslation'
import { StartExerciseButton } from './Buttons'
import { ClockIcon, QuestionsIcon, ExerciseIcon } from './icons'
import { useIsDesktop } from '../../hooks/useMediaQuery'

const StartExercise = ({ exerciseExists }) => {
  const { setStarted, saveExercise, formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const StartExercise = t('start_exercise.start_exercise')
  const ContinueExercise = t('start_exercise.continue_exercise')
  const buttonText = exerciseExists ? ContinueExercise : StartExercise
  const title = t('start_exercise.title')
  const description = t('start_exercise.description')
  const why_take_exercise = t('start_exercise.why_take_exercise')
  const isDesktop = useIsDesktop()
  const isMobile = !isDesktop

  const handleStartExerciseClick = () => {
    setStarted(true)
    if (exerciseExists) {
      setStarted(true)
    } else {
      saveExercise()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div className="relative p-10">
      {/* Action Button in Top-Right */}
      <div className="absolute right-8 top-8">
        {isDesktop && (
          <StartExerciseButton
            className="flex items-center gap-2 px-8 py-2 font-roboto text-base font-bold text-white transition-colors"
            onClick={handleStartExerciseClick}
          >
            {buttonText} <ExerciseIcon className="h-6 w-6" />
          </StartExerciseButton>
        )}
      </div>

      {/* Title Section */}
      <div>
        <h1 className="!mb-4 font-roboto-slab text-3xl font-bold text-gray-800">
          {title}
        </h1>
        <p className="!mb-4 !mt-0 text-lg font-medium text-gray-700">
          {why_take_exercise}
        </p>
      </div>

      {/* Description Section */}
      <div className="mb-8 space-y-2 text-base text-gray-600">
        {description.map((desc, idx) => (
          <p key={`description-p-${idx}`}>{desc}</p>
        ))}
      </div>

      {/* Info Cards */}
      <div className="mt-6 flex w-fit justify-start gap-8 rounded-[12px] border border-[#E5E5E5] px-6 py-4">
        <div className="flex items-center gap-3 text-gray-600">
          <ClockIcon className="h-6 w-6 text-primary" />
          <span className="text-base font-medium">
            {t('start_exercise.time')}
          </span>
        </div>
        <div className="flex items-center gap-3 border-l border-[#E5E5E5] pl-8 text-gray-600">
          <QuestionsIcon className="h-6 w-6 text-primary" />
          <span className="text-base font-medium">
            {t('start_exercise.questions_total')}
          </span>
        </div>
      </div>

      {isMobile && (
        <div className="mt-8 flex items-center justify-center">
          <StartExerciseButton
            className="flex items-center gap-2 px-8 py-2 font-roboto text-base font-bold text-white transition-colors"
            onClick={handleStartExerciseClick}
          >
            {buttonText} <ExerciseIcon className="h-5 w-5" />
          </StartExerciseButton>
        </div>
      )}
    </div>
  )
}

export default StartExercise
