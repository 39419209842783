import React from 'react'
import { usePersonalBrand } from './PersonalBrandProvider'
import RightNavListElement from './RightNavListElement'

const RightNavList = () => {
  const { exercises } = usePersonalBrand()
  return (
    <div className="mt-8 flex flex-col gap-4">
      {exercises.map((exercise, idx) => {
        if (idx !== exercises.length - 1) {
          return (
            <>
              <RightNavListElement
                key={`right-nav-exercise-${idx}`}
                exercise={exercise}
                className={'col-span-2 md:col-span-1'}
              />
              <hr className="border-bold  border-solid border-white"></hr>
            </>
          )
        } else {
          return (
            <RightNavListElement
              key={`right-nav-exercise-${idx}`}
              exercise={exercise}
              className={'col-span-2 md:col-span-1'}
            />
          )
        }
      })}
    </div>
  )
}

export default RightNavList
