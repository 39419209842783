import * as React from 'react'

import { useTranslation } from '../../../hooks/useTranslation'
import { NameCell } from './NameCell'
import { EmailCell } from './EmailCell'
import { PhoneCell } from './PhoneCell'
import { WebsiteCell } from './WebsiteCell'
export const columns = [
  {
    accessorKey: 'firm_name',
    filterFn: 'includesString',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <NameCell {...props} />,
  },
  {
    accessorKey: 'email',
    filterFn: 'includesString',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <EmailCell {...props} />,
  },
  {
    accessorKey: 'phone',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <PhoneCell {...props} />,
  },
  {
    accessorKey: 'website',
    filterFn: 'includesString',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <WebsiteCell {...props} />,
  },
]

function TableHeader({ column }) {
  const { t } = useTranslation('TableHeader')

  return <div>{t(column.id)}</div>
}
