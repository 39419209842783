import React from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import { ReturnToResourceCenter } from './Buttons'
import {
  CalendarIcon,
  ConclusionPageImage,
  ExerciseIcon,
  PersonIcon,
} from './icons'
import { useIsMobile } from '../../hooks/useMediaQuery'

const Conclusion = () => {
  const {
    formattedExerciseName,
    documentId,
    dateAndTimeUpdated,
    candidateFirstName,
    ssoName,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const conclusionGreatJob = t('conclusion_great_job')
  const conclusionP1 = t('conclusion_p1')
  const conclusionP2 = t('conclusion_p2')

  const link = {
    linkPath: `/documents/${documentId}.pdf`,
    linkText: t('conclusion_link'),
  }

  return (
    <div className="exercise mb-0">
      <Header
        t={t}
        ssoName={ssoName}
        candidateFirstName={candidateFirstName}
        dateAndTimeUpdated={dateAndTimeUpdated}
      />
      <SummaryContent
        conclusionGreatJob={conclusionGreatJob}
        conclusionP1={conclusionP1}
        conclusionP2={conclusionP2}
        link={link}
        t={t}
      />
    </div>
  )
}

const Header = ({ t, ssoName, candidateFirstName, dateAndTimeUpdated }) => {
  const isMobile = useIsMobile()
  return (
    <div className="bg-intoo-blue-medium-950 text-white">
      <div
        className={`${
          isMobile ? 'mx-4' : 'mx-8'
        } flex items-center justify-between ${isMobile ? 'pb-10' : 'pb-14'}`}
      >
        <div>
          <h1 className="mb-0 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal">
            {t(`start_exercise.title`)}
          </h1>
          <hr className="!b-0 my-4 h-[1px] w-32 bg-white text-white" />
          <div className="flex flex-col !font-roboto-slab text-[20px] sm:flex-row">
            {!ssoName && (
              <div
                className={`${
                  isMobile ? 'mb-4' : ''
                } mr-0 flex items-center sm:mr-12`}
              >
                <div className="mr-4">
                  <PersonIcon />
                </div>
                {`${candidateFirstName}'s`} {t('results')}
              </div>
            )}
            <div className="flex items-center">
              <div className="mr-4">
                <CalendarIcon />
              </div>
              {dateAndTimeUpdated}
            </div>
          </div>
        </div>
        <div className="mt-10 flex">
          <ExerciseIcon />
        </div>
      </div>
    </div>
  )
}

const SummaryContent = ({
  conclusionGreatJob,
  conclusionP1,
  conclusionP2,
  link,
  t,
}) => {
  const isMobile = useIsMobile()
  const { formattedExerciseName } = useExercise()

  return (
    <div
      className={`summary-content ${
        isMobile ? 'p-6' : 'p-12'
      } flex flex-col bg-[#E0F2F9]`}
    >
      <div
        className={`rounded-[20px] bg-white ${
          isMobile ? 'px-4 py-12' : 'px-12 py-12'
        }`}
      >
        <div className="text-center">
          <h1 className="mb-6 text-center font-roboto text-2xl font-medium">
            {conclusionGreatJob}
          </h1>
          <LinkifiedCopy copy={conclusionP1} link={link} />
          <div className="mx-auto my-6 flex justify-center">
            <ConclusionPageImage />
          </div>
        </div>
        {formattedExerciseName === 'design_thinking' && (
          <p className="!mb-6 font-roboto text-base font-normal">
            {conclusionP2}
          </p>
        )}
        {formattedExerciseName === 'motivation_reflection' && (
          <div className="flex justify-center">
            <a
              href={link.linkPath}
              target="_blank"
              rel="noopener noreferrer"
              className="!underline"
            >
              {t('conclusion_link')}
            </a>
          </div>
        )}
        <ReturnToResourceCenter />
      </div>
    </div>
  )
}

const LinkifiedCopy = ({ copy, link }) => {
  const [copy1, copy2] = copy.split('[link]')
  const { linkPath, linkText } = link
  return (
    <p className="font-roboto text-lg font-medium">
      {copy1}
      {copy2 && (
        <a
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className="!underline"
        >
          {linkText}
        </a>
      )}
      {copy2}
    </p>
  )
}

export default Conclusion
