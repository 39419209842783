import React from 'react'
import { cn } from '../../lib/utils'
import RightNavList from './RightNavList'

const RightNav = ({ className }) => {
  return (
    <>
      <div
        className={cn(
          'reminders-div relative bg-primary p-4 text-white md:col-span-3 md:h-full',
          className,
        )}
      >
        <RightNavList />
      </div>
    </>
  )
}

export default RightNav
