import React from 'react'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { ExerciseProvider, useExercise } from './ExerciseProvider'
import Exercise from './Exercise'
import StartExercise from './StartExercise'
import Conclusion from './Conclusion'

const Index = ({ ...props }) => {
  return (
    <ExerciseProvider {...props}>
      <ExerciseComponents {...props} />
    </ExerciseProvider>
  )
}

export default Index

const ExerciseComponents = ({ locale }) => {
  const { documentId, started, showConclusion } = useExercise()

  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope={'javascript.components.Exercises'}
    >
      {showConclusion && <Conclusion />}
      {!started && !showConclusion && (
        <StartExercise exerciseExists={!!documentId} />
      )}
      {started && !showConclusion && <Exercise />}
    </TranslationProvider>
  )
}
