import React, { useEffect, useState } from 'react'
import { AssessmentsIcon } from './icons'
import { cn } from '../../lib/utils'
import { HTMLToJSON } from 'html-to-json-parser'

const AssessmentCard = ({ className, assessment }) => {
  const { title, description, image, status, alt } = assessment

  const [path, setPath] = useState('')
  const [cta, setCta] = useState('')
  const [data, setData] = useState({})

  useEffect(() => {
    const convert = async () => {
      const linkInfo = await HTMLToJSON(assessment.path, false)
      setPath(linkInfo.attributes.href)
      setCta(linkInfo.content[0])
      const data = {
        'data-notification': linkInfo.attributes['data-notification'],
        'data-points': linkInfo.attributes['data-points'],
        'data-text': linkInfo.attributes['data-text'],
      }
      setData(data)
    }
    convert()
  }, [assessment.path])

  const completedButtonClasses =
    'bg-white text-[#2F2F2F] hover:!bg-accent focus:bg-accent focus:border-accent  focus:text-[#2F2F2F] active:bg-accent active:border-accent hover:text-[#2F2F2F] border-[#2F2F2F] active:text-[#2F2F2F]'

  const incompleteButtonCLasses =
    'bg-primary text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white'

  return (
    <div
      className={cn(
        'grid grid-cols-1 gap-0 rounded-3xl border border-solid border-[#B9B9B9] md:min-h-[350px] md:grid-cols-3',
        className,
      )}
    >
      <div className="h-full overflow-hidden rounded-tl-[20px] rounded-tr-[20px] md:rounded-[20px]">
        <img
          src={image}
          alt={alt}
          className="aspect-[8/5] h-full w-full object-cover"
        />
      </div>

      <div className="flex flex-col justify-between p-4 md:col-span-2">
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 truncate text-xl font-bold focus:overflow-visible"
          title={title}
        >
          {title}
        </h2>

        <p className="!my-0 line-clamp-3 flex-grow">{description}</p>

        <div className="mt-6 self-center">
          <a
            href={path}
            className={cn(
              'flex w-full items-center justify-center rounded-3xl border px-4 py-3 text-base font-medium sm:py-2',
              status === 'completed'
                ? completedButtonClasses
                : incompleteButtonCLasses,
            )}
            data-notification={data['data-notification']}
            data-points={data['data-points']}
            data-text={data['data-text']}
          >
            {cta}
            <AssessmentsIcon
              className={cn(
                'ml-2',
                status === 'completed' ? 'stroke-black' : 'stroke-white',
              )}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default AssessmentCard
