import React from 'react'
import { useExercise } from './ExerciseProvider'
import DesignThinkingExercise from './DesignThinkingExercise'
import MotivationReflectionExercise from './MotivationReflectionExercise'

const Layout = ({ pageInfo, section }) => {
  const {
    updateResponse,
    getResponse,
    exerciseName,
    exerciseIndex,
    trackContent,
  } = useExercise()
  const description = pageInfo.description
  const conclusion = pageInfo.conclusion
  const questions = Object?.values(pageInfo.questions ?? {})
  const title = pageInfo.title

  const handleChange = (section, slug, event) => {
    const response =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    updateResponse(section, slug, response)
  }

  const handleDivClick = (section, slug) => {
    const currentValue = getResponse(section, slug) || false
    updateResponse(section, slug, !currentValue)
  }

  return (
    <div className="mx-auto p-8">
      <h1 className="mb-4 font-roboto-slab text-3xl font-bold">{title}</h1>
      <p className="!mb-6 font-roboto text-lg">{description}</p>
      {exerciseName === 'Design Thinking' && (
        <DesignThinkingExercise
          section={section}
          questions={questions}
          handleDivClick={handleDivClick}
          handleChange={handleChange}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          trackContent={trackContent}
          conclusion={conclusion}
        />
      )}
      {exerciseName === 'Motivation Reflection' && (
        <MotivationReflectionExercise
          questions={questions}
          handleDivClick={handleDivClick}
          handleChange={handleChange}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
    </div>
  )
}

export default Layout
