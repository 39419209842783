import React, { forwardRef } from 'react'
import { useDroppable, useDraggable } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Button } from '../../lib/ui/button'

export const Droppable = ({ id, children, ...props }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  })

  const style = {
    backgroundColor: isOver ? '#BFDCF5' : undefined,
  }

  return (
    <div ref={setNodeRef} {...props} style={style}>
      {children}
    </div>
  )
}

export const Draggable = ({ id, children, ...props }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  })

  const style = { transform: CSS.Translate.toString(transform) }

  return (
    <Button
      onClick={(e) => e.preventDefault()}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      {...props}
    >
      {children}
    </Button>
  )
}

export const SortableItem = ({ id, children, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      {...attributes}
      {...listeners}
      {...props}
      ref={setNodeRef}
      style={style}
    >
      {children}
    </div>
  )
}

export const Item = forwardRef(({ id, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      {id}
    </div>
  )
})
Item.displayName = 'Item'
