import * as React from 'react'
export const useFilters = ({ form, setUrlSearchParams }) => {
  const { watch } = form

  const what = watch('what')
  const where = watch('where')
  const filter_firm_type = watch('filter_firm_type')
  const filter_salary = watch('filter_salary')
  const filter_industry = watch('filter_industry')

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) => {
        if (what) prev.set('what', what)
        else prev.delete('what')

        if (where) prev.set('where', where)
        else prev.delete('where')

        if (filter_industry) prev.set('filter_industry', filter_industry)
        else prev.delete('filter_industry')

        return prev
      },
      { replace: true },
    )
  }, [what, where, filter_industry, setUrlSearchParams])

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) =>
        addArrayToUrlSearchParams(prev, 'filter_firm_type', filter_firm_type),
      { replace: true },
    )
  }, [filter_firm_type, setUrlSearchParams])

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) => addArrayToUrlSearchParams(prev, 'filter_salary', filter_salary),
      { replace: true },
    )
  }, [filter_salary, setUrlSearchParams])

  const hasFilters =
    filter_firm_type.length || filter_salary.length || filter_industry.length

  const resetFilters = () => {
    form.setValue('filter_firm_type', [])
    form.setValue('filter_salary', [])
    form.setValue('filter_industry', '')
  }

  return {
    hasFilters,
    resetFilters,
  }
}

// TODO: Move this to a shared location
export const addArrayToUrlSearchParams = (urlSearchParams, key, array) => {
  if (typeof array === 'string') {
    array = [array]
  }

  urlSearchParams.delete(`${key}[]`)
  array.forEach((value) => {
    urlSearchParams.append(`${key}[]`, value)
  })
  urlSearchParams.sort()

  return urlSearchParams
}
