import React, { useState } from 'react'
import { FileField } from './ExpandedCell'
import { Form } from '../../../lib/ui/form'
import useBulkSavedRecruitersForm from './useBulkSavedRecruitersForm'
import { Button } from '../../../lib/ui/button'
import useTranslation from '../../shared/TranslationProvider'
import { Check } from 'lucide-react'

const BulkUploadForm = () => {
  const [complete, setComplete] = useState(false)
  const { form, onSubmit, submitted } = useBulkSavedRecruitersForm(setComplete)
  const { t } = useTranslation('FormActions')
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <FileField
            control={form.control}
            name="remote_content"
            accept={'csv'}
            label={t('bulk_upload')}
          />
          <div className="hint">{t('bulk_upload_hint')}</div>
        </div>
        <Button type="submit" disabled={submitted} className={'w-full'}>
          {t('upload')}
          {complete && <Check />}
        </Button>
      </form>
    </Form>
  )
}
export default BulkUploadForm
