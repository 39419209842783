import React from 'react'
import { Button } from '../../lib/ui/button'
import { cn } from '../../lib/utils'
import { useIsDesktop } from '../../hooks/useMediaQuery'

const buttonClasses =
  'rounded-full flex justify-center items-center gap-2 py-0 px-6'
const buttonWidthClasses = 'w-[120px] sm:w-[172px] h-12 sm:h-10'
const primaryButtonClasses =
  'bg-primary-button-cd hover:bg-primary-button-cd-darker-75'
const backButtonClasses = 'border-[#5A5A5A] bg-[#fff] hover:bg-[#F0F0F0]'

export const StartExerciseButton = ({ className, children, ...props }) => (
  <Button {...props} className={cn(className, primaryButtonClasses)}>
    {children}
  </Button>
)

export const PrimaryExerciseButton = ({ className, children, ...props }) => (
  <Button
    {...props}
    className={cn(
      buttonClasses,
      buttonWidthClasses,
      className,
      primaryButtonClasses,
    )}
  >
    {children}
  </Button>
)

export const BackExerciseButton = ({ className, children, ...props }) => (
  <Button
    {...props}
    variant="outline"
    className={cn(
      buttonClasses,
      buttonWidthClasses,
      className,
      backButtonClasses,
    )}
  >
    {children}
  </Button>
)

export const ReturnToResourceCenter = () => {
  const handleClick = () => {
    const path = window.location.pathname.split('/')[1]
    const newUrl = `/${path}/resources`
    window.location.replace(newUrl)
  }
  const isDesktop = useIsDesktop()

  return (
    <div
      className={`mt-6 flex flex-col items-center gap-6 ${
        !isDesktop ? 'mb-5' : ''
      }`}
    >
      <PrimaryExerciseButton
        onClick={handleClick}
        className="w-[200px] sm:w-[200px]"
      >
        Return to Resource Center
      </PrimaryExerciseButton>
    </div>
  )
}
