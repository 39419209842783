import * as React from 'react'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
} from '../../../lib/ui/popover'
import ChevronDownIcon from '../JobSearchPage/ChevronDownIcon'
import { ShowResultsButton } from './buttons'
import XMarkIcon from '../JobSearchPage/XMarkIcon'

export default function FilterDropdown({
  title,
  align = 'start',
  hasChecked = false,
  show = true,
  children,
}) {
  const [open, setOpen] = React.useState(false)

  if (!show) {
    return null
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className="flex h-8 items-center justify-center gap-0 whitespace-nowrap rounded-full border border-alto-300 border-input bg-background bg-intoo-haze pl-3 pr-2 text-xs font-medium italic text-muted-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-intoo-blue-medium focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[has-checked=true]:bg-intoo-blue-bright-100 data-[has-checked=true]:text-black sm:gap-2 sm:pl-6 sm:pr-4 md:h-9 md:text-base"
        data-has-checked={hasChecked}
      >
        {title} <ChevronDownIcon />
      </PopoverTrigger>
      <PopoverContent
        portal={false}
        align={align}
        className="relative w-auto min-w-72 rounded-3xl border-alto-300 px-6 pt-2"
      >
        <div className="mb-6 mt-4">{children}</div>
        <ShowResultsButton
          onClick={() => {
            setTimeout(() => setOpen(false), 0)
          }}
        />
        <PopoverCloseButton className="absolute right-4 top-4">
          <XMarkIcon />
        </PopoverCloseButton>
      </PopoverContent>
    </Popover>
  )
}
