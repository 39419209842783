import React, { createContext, useContext, useState } from 'react'

const ProgramEvaluationContext = createContext()

export const useProgramEvaluation = () => {
  return useContext(ProgramEvaluationContext)
}

const ProgramEvaluationProvider = ({
  type,
  inplatform,
  peakPerformance,
  executive,
  coachingProgram,
  programEvaluation,
  candidate,
  company,
  secret,
  completed,
  managerImprovementAreas,
  completedPath,
  topicSelectionInitialState,
  sessionTopicCategories,
  totalTopicsAllowed,
  topicSelectionCompleted,
  completedTopicSelectionPath,
  topicsSelectedByManager,
  managerInfoPresent,
  parentalLeave,
  annualBenefit,
  postProgramImprovementAreas,
  postProgramImprovementObjectives,
  postProgramManagerImprovementAreas,
  postProgramManagerImprovementObjectives,
  coachFit,
  cliftonStrengths,
  groupProgram,
  classes,
  children,
}) => {
  const outplatform = !inplatform
  const { programId, programName, hrSelectsTopics } = coachingProgram
  const [topicSelection, setTopicSelection] = useState(
    topicSelectionInitialState,
  )
  const preProgramEvaluationInitialState =
    (type === 'PreProgramEvaluation' ||
      type === 'ManagerPreProgramEvaluation') &&
    !topicSelection

  const postProgramEvaluationInitialState = type === 'PostProgramEvaluation'

  const midProgramEval = type === 'MidProgramEvaluation'

  const [preProgramEvaluation, setPreProgramEvaluation] = useState(
    preProgramEvaluationInitialState,
  )

  const [postProgramEvaluation, setPostProgramEvaluation] = useState(
    postProgramEvaluationInitialState,
  )

  const [longTermGoalsBlob, setLongTermGoalsBlob] = useState('')
  const [longTermGoalsSrc, setLongTermGoalsSrc] = useState('')
  const [coachingGoalsBlob, setCoachingGoalsBlob] = useState('')
  const [coachingGoalsSrc, setCoachingGoalsSrc] = useState('')
  const [description, setDescription] = useState(coachFit)

  const executiveEval = type === 'PreProgramEvaluation' && executive
  const professionalEval =
    type === 'PreProgramEvaluation' &&
    !executive &&
    !peakPerformance &&
    !parentalLeave &&
    !annualBenefit

  const managerEval = type === 'ManagerPreProgramEvaluation'

  const managerPostEval = type === 'ManagerPostProgramEvaluation'

  const peakPerformancePostEval =
    type === 'PostProgramEvaluation' && peakPerformance

  const [thankYouPage, setThankYouPage] = useState(false)
  const [topicSelectionThankYouPage, setTopicSelectionThankYouPage] =
    useState(false)

  const [selectedTopics, setSelectedTopics] = useState([])

  const allTopicsSelected = selectedTopics.length === totalTopicsAllowed

  const [topicSelectionContinueLink, setTopicSelectionContinueLink] =
    useState('')

  const companyName = company.career_development_settings?.platform_name

  const postSessionEval = !!coachFit

  const programEvalId = programEvaluation?.id

  const value = {
    programEvalId,
    inplatform,
    outplatform,
    peakPerformance,
    executiveEval,
    professionalEval,
    programId,
    programName,
    programEvaluation,
    candidate,
    company,
    secret,
    completed,
    preProgramEvaluation,
    setPreProgramEvaluation,
    managerEval,
    managerImprovementAreas,
    type,
    thankYouPage,
    setThankYouPage,
    completedPath,
    topicSelection,
    setTopicSelection,
    sessionTopicCategories,
    totalTopicsAllowed,
    selectedTopics,
    setSelectedTopics,
    allTopicsSelected,
    topicSelectionThankYouPage,
    setTopicSelectionThankYouPage,
    topicSelectionContinueLink,
    setTopicSelectionContinueLink,
    topicSelectionCompleted,
    completedTopicSelectionPath,
    topicsSelectedByManager,
    managerInfoPresent,
    parentalLeave,
    annualBenefit,
    postProgramEvaluation,
    setPostProgramEvaluation,
    postProgramImprovementAreas,
    postProgramImprovementObjectives,
    postProgramManagerImprovementAreas,
    postProgramManagerImprovementObjectives,
    companyName,
    managerPostEval,
    peakPerformancePostEval,
    hrSelectsTopics,
    midProgramEval,
    postSessionEval,
    cliftonStrengths,
    groupProgram,
    coachFit,
    longTermGoalsBlob,
    setLongTermGoalsBlob,
    coachingGoalsBlob,
    setCoachingGoalsBlob,
    longTermGoalsSrc,
    setLongTermGoalsSrc,
    coachingGoalsSrc,
    setCoachingGoalsSrc,
    description,
    setDescription,
    classes,
  }
  return (
    <ProgramEvaluationContext.Provider value={value}>
      {children}
    </ProgramEvaluationContext.Provider>
  )
}

export default ProgramEvaluationProvider
