import React from 'react'
import ExampleScripts from './ExampleScripts'
import parse from 'html-react-parser'
import HighlightCommentProvider, {
  useHighlightComment,
} from './HighlightCommentProvider'
import {
  NumberedList,
  TitledBulletList,
  TitledList,
  List,
  BulletedList,
  NestedBulletList,
} from './lists'
import CommentBox from './CommentBox'
import LeaveSiteWarning from '../shared/LeaveSiteWarning'

const Section = ({ section }) => {
  const { title, children, exclude_title } = section
  return (
    <>
      {!exclude_title && (
        <h2 className="!mt-0 mb-4 text-2xl font-bold text-primary">{title}</h2>
      )}
      {children.map((child, index) => (
        <HighlightCommentProvider
          locatorSuffix={`.children[${index}]`}
          key={index}
        >
          <ChildSection child={child} />
        </HighlightCommentProvider>
      ))}
    </>
  )
}

const ChildSection = ({ child }) => {
  const { type } = child
  if (type === 'paragraph') {
    return <Paragraph paragraphObject={child} />
  } else if (type === 'numbered-list') {
    return <NumberedList listObject={child} />
  } else if (type === 'bulleted-list') {
    return <BulletedList listObject={child} />
  } else if (type === 'nested-bullet-list') {
    return <NestedBulletList listObject={child} />
  } else if (type === 'titled-bullet-list') {
    return <TitledBulletList listObject={child} />
  } else if (type === 'titled-list') {
    return <TitledList listObject={child} />
  } else if (type == 'example-scripts') {
    return <ExampleScripts exampleScriptsObject={child} />
  } else if (type == 'example-form') {
    return <ExampleForm formObject={child} />
  } else if (type == 'list') {
    return <List listObject={child} />
  } else if (type === 'header') {
    return <Header headerObject={child} />
  } else if (type === 'column-flow-grid') {
    return <ColumnFlowGrid columnFlowGridObjects={child} />
  } else if (type === 'table') {
    return <Table tableObject={child} />
  } else if (type === 'indent') {
    return <Indent indentObject={child} />
  } else if (type === 'example') {
    return <Example exampleObject={child} />
  } else if (type === 'tip') {
    return <Tip tipObject={child} />
  }
}

const ExampleFormItem = ({ item }) => {
  const { title, required } = item
  const name = title.toLowerCase().replace(' ', '_').replace('*', '')
  const displayTitle = required ? `${title}*` : title
  return (
    <div className="mb-2 flex flex-row items-center justify-between">
      <label htmlFor={name}>{displayTitle}</label>
      <input
        id={name}
        name={name}
        type="text"
        disabled="disabled"
        className="cursor-not-allowed rounded-md bg-gray-100"
        required={required}
      />
    </div>
  )
}

const ExampleForm = ({ formObject }) => {
  const { children } = formObject
  return (
    <div className="mt-8 max-w-xs first:mt-0">
      {children.map((child, index) => (
        <ExampleFormItem key={index} item={child} />
      ))}
    </div>
  )
}

const Paragraph = ({ paragraphObject, additionalClass }) => {
  const { content, plugins, title } = paragraphObject
  const { actionProps, highlightClass } = useHighlightComment()

  const text =
    plugins && plugins.includes('parse-html') ? parse(content) : content

  let pClass = ''
  if (plugins) {
    pClass += plugins.join(' ')
  }
  if (highlightClass) {
    pClass += ' ' + highlightClass
  }
  if (additionalClass) {
    pClass += ' ' + additionalClass
  }

  const titleClass = `mt-6 text-xl font-semibold ${additionalClass || ''}`

  return (
    <>
      {title && <div className={titleClass}>{title}</div>}
      <div className="mt-4 first:mt-0">
        <p className={pClass} {...actionProps}>
          {text}
        </p>
        <CommentBox />
      </div>
    </>
  )
}

const Indent = ({ indentObject }) => {
  return <Paragraph paragraphObject={indentObject} additionalClass="ml-5" />
}

const Header = ({ headerObject }) => {
  const { title, size } = headerObject
  const Tag = `h${size}`

  const classNames = {
    1: 'mt-4 font-bold text-2xl',
    2: 'mt-4 font-semibold text-xl',
    3: 'mt-4 font-semibold text-lg',
    4: 'mt-4 font-medium text-base',
    5: 'mt-4 font-medium text-sm',
    6: 'mt-4 font-medium text-xs',
  }[size]

  return <Tag className={classNames}>{title}</Tag>
}

const ColumnFlowGrid = ({ columnFlowGridObjects }) => {
  return (
    <div className="grid grid-cols-2 gap-4 py-5 lg:grid-cols-3">
      {columnFlowGridObjects.children.map((obj, index) => (
        <LeaveSiteWarning key={index} url={obj.url}>
          <a className="!underline">{obj.label}</a>
        </LeaveSiteWarning>
      ))}
    </div>
  )
}

const Table = ({ tableObject }) => {
  const { title, headers, rows } = tableObject

  return (
    <div className="my-4">
      <div className="text-center">
        {title && <div className="font-semibold">{title}</div>}
        <div className="mx-auto w-max border p-4">
          <table className="w-full table-auto">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} className="px-4 py-2">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className="px-4 py-2">
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const Example = ({ exampleObject }) => {
  const { content, plugins, title } = exampleObject

  const parsedContent =
    plugins && plugins.includes('parse-html') ? parse(content) : content

  return (
    <div className="mt-4 rounded-3xl bg-[#EAF4FF] px-4 pb-10 pt-4 first:mt-0 sm:px-24 sm:pt-4">
      <div className="mb-4 flex font-bold">
        <span className="text-xl italic">{title ? title : 'Example:'}</span>
      </div>
      <p>{parsedContent}</p>
    </div>
  )
}

const Tip = ({ tipObject }) => {
  const { svg, plugins, content } = tipObject

  const parsedContent =
    plugins && plugins.includes('parse-html') ? parse(content) : content

  return (
    <div className="flex max-w-5xl flex-row gap-2">
      <svg width="60" height="60">
        {parse(svg)}
      </svg>
      <p className="mt-2">{parsedContent}</p>
    </div>
  )
}

export default Section
