import React, { useEffect } from 'react'

import { Form } from '../../../lib/ui/form'

import { useJobSearch } from './JobSearchProvider'
import MagnifyingGlassIcon from './MagnifyingGlassIcon'
import MapPinIcon from './MapPinIcon'
import SearchInput from './SearchInput'
import { PrimaryButton } from './buttons'
import FilterCheckboxGroup from './FilterCheckboxGroup'
import FilterDropdown from './FilterDropdown'
import { Button } from '../../../lib/ui/button'
import { useTranslation } from '../../../hooks/useTranslation'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import FilterToggleButton from './FilterToggleButton'
import FilterCheckbox from './FilterCheckbox'
import { BellAlertIcon } from '@heroicons/react/24/outline'

export default function ToolBar() {
  const {
    form,
    onSubmit,
    hasFilters,
    urlSearchParams,
    resetFilters,
    isIntooItaly,
    isDemo,
    sort,
    setSort,
  } = useJobSearch()
  const { t } = useTranslation('ToolBar')
  const isDesktop = useIsDesktop()
  const isMobile = !isDesktop
  const [showAllFilters, setShowAllFilters] = React.useState(isDesktop)
  React.useEffect(() => {
    setShowAllFilters(isDesktop)
  }, [isDesktop])

  const sortMostRecent = (e) => {
    e.preventDefault()
    setSort('most_recent')
  }

  const sortRelevance = (e) => {
    e.preventDefault()
    setSort('relevance')
  }

  useEffect(() => {
    document.getElementById('search-jobs-button')?.click()
  }, [sort])

  const activeSortClasses = 'cursor-pointer !underline'
  const inactiveSortClasses = 'cursor-pointer'

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex max-w-5xl flex-col">
          <h1 className="mb-4 font-gothamRegular text-[#333]">
            {t('search_for_jobs')}
          </h1>

          <div className="mb-6 flex basis-1/2 flex-row justify-between gap-3 align-text-bottom md:basis-1/3 md:gap-4">
            <SearchInputWhat />

            <SearchInputWhere />

            <DesktopSearchButton />
          </div>

          <div className="flex flex-row flex-wrap gap-2 md:gap-4">
            <FilterDropdown
              title={t('experience_level')}
              hasChecked={urlSearchParams.has('filter_experience_level[]')}
            >
              <ExperienceLevelCheckboxes />
            </FilterDropdown>

            <FilterDropdown
              title={t('job_type')}
              hasChecked={urlSearchParams.has('filter_job_type[]')}
            >
              <JobTypeCheckboxes />
            </FilterDropdown>

            <FilterDropdown
              title={t('date_posted')}
              hasChecked={urlSearchParams.has('filter_date_posted[]')}
            >
              <DatePostedCheckboxes />
            </FilterDropdown>

            <FilterDropdown
              title={t('employment_type')}
              hasChecked={urlSearchParams.has('filter_employment_type[]')}
              show={showAllFilters}
            >
              <EmploymentTypeCheckboxes />
            </FilterDropdown>

            {isIntooItaly && isDemo && (
              <FilterToggleButton
                title={t('job_bank_jobs')}
                pressed={urlSearchParams.has('filter_job_bank_jobs')}
                onPressedChange={(pressed) => {
                  const value = pressed ? true : false
                  form.setValue('filter_job_bank_jobs', value)
                }}
                show={showAllFilters}
              />
            )}

            <AllFiltersDropdown show={showAllFilters} />

            <div className="flex flex-col justify-around">
              <a
                href="/job-search/alerts/new?last_search=true"
                className="text-primary"
              >
                <BellAlertIcon className="inline h-6 w-6 pr-2" />
                Create Job Alert
              </a>
            </div>

            <div className="">
              {!!hasFilters && showAllFilters && (
                <Button
                  size="sm"
                  type="button"
                  variant="link"
                  className="text-xs"
                  onClick={async () => {
                    await resetFilters()
                    onSubmit()
                  }}
                >
                  {t('reset_filters')}
                </Button>
              )}
            </div>
          </div>
          <div className="-mt-2">
            {isMobile && showAllFilters && (
              <Button
                size="sm"
                type="button"
                variant="link"
                className="text-xs"
                onClick={() => setShowAllFilters(false)}
              >
                {t('view_less')}
              </Button>
            )}
            {isMobile && !showAllFilters && (
              <Button
                size="sm"
                type="button"
                variant="link"
                className="text-xs"
                onClick={() => setShowAllFilters(true)}
              >
                {t('view_all_filters')}
              </Button>
            )}
          </div>

          <MobileSearchButton />
        </div>
        <div className="flex flex-row justify-start gap-2 pt-6">
          <span>Sort By:</span>
          <a
            onClick={sortMostRecent}
            className={
              sort == 'most_recent' ? activeSortClasses : inactiveSortClasses
            }
          >
            Most Recent
          </a>
          |
          <a
            onClick={sortRelevance}
            className={
              sort == 'relevance' ? activeSortClasses : inactiveSortClasses
            }
          >
            Relevance
          </a>
        </div>
      </form>
    </Form>
  )
}

function SearchInputWhat() {
  const { t } = useTranslation('SearchInputWhat')
  const { form } = useJobSearch()

  return (
    <SearchInput
      control={form.control}
      name="what"
      label={t('label')}
      icon={MagnifyingGlassIcon}
      placeholder={t('placeholder')}
      description={t('description')}
      data-source="roles"
    />
  )
}

function SearchInputWhere() {
  const { t } = useTranslation('SearchInputWhere')
  const { form } = useJobSearch()

  return (
    <SearchInput
      control={form.control}
      name="where"
      label={t('label')}
      icon={MapPinIcon}
      placeholder={t('placeholder')}
      description={t('description')}
      data-source="locations"
    />
  )
}

function DesktopSearchButton() {
  const isDesktop = useIsDesktop()
  const { t } = useTranslation('DesktopSearchButton')

  if (!isDesktop) return null

  return (
    <div className="w-full pt-2">
      <PrimaryButton id="search-jobs-button">{t('search_jobs')}</PrimaryButton>
    </div>
  )
}

function MobileSearchButton() {
  const isDesktop = useIsDesktop()
  const { t } = useTranslation('MobileSearchButton')

  if (isDesktop) return null

  return (
    <div className="flex flex-row">
      <PrimaryButton id="search-jobs-button">{t('search_jobs')}</PrimaryButton>
    </div>
  )
}

function AllFiltersDropdown({ show = true }) {
  const { t } = useTranslation('AllFiltersDropdown')
  const isDesktop = useIsDesktop()
  const { isIntooItaly } = useJobSearch()

  if (!show) return null

  if (isDesktop)
    return (
      <FilterDropdown title={t('all_filters')} align="end">
        <h3 className="pb-4 text-2xl font-bold">{t('all_filters')}</h3>
        <div className="flex flex-row flex-wrap gap-6">
          <ExperienceLevelCheckboxes showLabel />
          <JobTypeCheckboxes showLabel />
          <EmploymentTypeCheckboxes showLabel />
          <DatePostedCheckboxes showLabel />
          {isIntooItaly && <JobBankJobsCheckbox showLabel />}
        </div>
      </FilterDropdown>
    )

  return (
    <FilterDropdown title={t('all_filters')} align="end">
      <div className="flex flex-col gap-4">
        <h3 className="text-xl font-bold">{t('all_filters')}</h3>
        <div className="flex flex-row flex-wrap gap-4">
          <ExperienceLevelCheckboxes showLabel />
          <JobTypeCheckboxes showLabel />
        </div>
        <div className="flex flex-row flex-wrap gap-4">
          <EmploymentTypeCheckboxes showLabel />
          <DatePostedCheckboxes showLabel />
        </div>
        <div className="flex flex-row flex-wrap gap-4">
          {isIntooItaly && <JobBankJobsCheckbox showLabel />}
        </div>
      </div>
    </FilterDropdown>
  )
}

function ExperienceLevelCheckboxes({ showLabel }) {
  const { t } = useTranslation('ExperienceLevelCheckboxes')

  return (
    <FilterCheckboxGroup
      name="filter_experience_level"
      label={t('label')}
      showLabel={showLabel}
      description={t('description')}
      items={[
        { id: 'internship', label: t('items.internship') },
        { id: 'entrylevel', label: t('items.entrylevel') },
        { id: 'midseniorlevel', label: t('items.midseniorlevel') },
        { id: 'manager', label: t('items.manager') },
        { id: 'director', label: t('items.director') },
        { id: 'executive', label: t('items.executive') },
      ]}
    />
  )
}

function JobTypeCheckboxes({ showLabel }) {
  const { t } = useTranslation('JobTypeCheckboxes')

  return (
    <FilterCheckboxGroup
      name="filter_job_type"
      label={t('label')}
      showLabel={showLabel}
      description={t('description')}
      items={[
        { id: 'onsite', label: t('items.onsite') },
        { id: 'remote', label: t('items.remote') },
        { id: 'hybrid', label: t('items.hybrid') },
      ]}
    />
  )
}

function EmploymentTypeCheckboxes({ showLabel }) {
  const { t } = useTranslation('EmploymentTypeCheckboxes')

  return (
    <FilterCheckboxGroup
      name="filter_employment_type"
      label={t('label')}
      showLabel={showLabel}
      description={t('description')}
      items={[
        { id: 'fulltime', label: t('items.fulltime') },
        { id: 'parttime', label: t('items.parttime') },
        { id: 'contract', label: t('items.contract') },
      ]}
    />
  )
}

function DatePostedCheckboxes({ showLabel }) {
  const { t } = useTranslation('DatePostedCheckboxes')

  return (
    <FilterCheckboxGroup
      name="filter_date_posted"
      label={t('label')}
      showLabel={showLabel}
      description={'description'}
      items={[
        { id: 'any_time', label: t('items.any_time') },
        { id: '1', label: t('items.today') },
        { id: '7', label: t('items.past_week') },
        { id: '30', label: t('items.past_month') },
      ]}
    />
  )
}

function JobBankJobsCheckbox() {
  const { t } = useTranslation('JobBankJobsCheckbox')
  const { form } = useJobSearch()

  return (
    <FilterCheckbox
      name="filter_job_bank_jobs"
      control={form.control}
      label={t('label')}
      description={t('description')}
      itemLabel={t('item_label')}
    />
  )
}
