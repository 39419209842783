import React from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'

const DesignThinkingSectionThree = ({ data }) => {
  const { formattedExerciseName, updateResponse, dispatch } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  const sectionTwoData = data[2] ? filterSectionTwoData(data[2]) : {}
  const additionalIdeas = sectionTwoData.additional_ideas || []
  const filteredAdditionalIdeas = additionalIdeas.filter(
    (idea) => idea.idea !== '',
  )

  const filteredSectionTwoData = removeAdditionalIdeas(sectionTwoData)
  const pages = t('pages')
  const slugToQuestionMap = getSlugToQuestionMap(pages['2'].questions)

  const handleDateChange = (key, date) => {
    updateResponse(2, key, key, date)
  }

  const handleIdeaDateChange = (index, date) => {
    dispatch({ type: 'UPDATE_COMPLETION_DATE', payload: { index, date } })
    const updatedIdeas = additionalIdeas.map((idea, i) =>
      i === index ? { ...idea, completionDate: date } : idea,
    )
    updateResponse(2, 'additional_ideas', updatedIdeas)
  }

  const isSectionTwoDataEmpty =
    Object.keys(filteredSectionTwoData).length === 0 ||
    Object.values(filteredSectionTwoData).every((value) => value === null)
  const isAdditionalIdeasEmpty = additionalIdeas.length === 0

  return (
    <div className="rounded-lg bg-white p-8 shadow-lg">
      {isSectionTwoDataEmpty && isAdditionalIdeasEmpty ? (
        <ReturnToStep3Message />
      ) : (
        <div className="space-y-8">
          {Object.keys(filteredSectionTwoData).map((key) => {
            const value =
              typeof filteredSectionTwoData[key] === 'boolean'
                ? ''
                : filteredSectionTwoData[key]
            return (
              <DataInput
                key={key}
                question={slugToQuestionMap[key]}
                value={value}
                onChange={(date) => handleDateChange(key, date)}
              />
            )
          })}
          {filteredAdditionalIdeas.map((idea, index) => (
            <DataInput
              key={index}
              question={idea.idea}
              value={idea.completionDate}
              onChange={(date) => handleIdeaDateChange(index, date)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const filterSectionTwoData = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (!(key.startsWith('checkbox_') && data[key] === false)) {
      acc[key] = data[key]
    }
    return acc
  }, {})
}

const removeAdditionalIdeas = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (key !== 'additional_ideas') {
      acc[key] = data[key]
    }
    return acc
  }, {})
}

const getSlugToQuestionMap = (questions) => {
  return questions.reduce((acc, questionObj) => {
    acc[questionObj.slug] = questionObj.question
    return acc
  }, {})
}

const DataInput = ({ question, value, onChange }) => (
  <div className="flex flex-col items-start justify-between rounded-lg border border-[#D3D3D3] bg-[#F7FAFC] p-4 md:flex-row md:items-center md:px-4 md:py-3">
    <span className="font-roboto text-base font-medium md:mb-0">
      {question}
    </span>
    <div className="flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-4 md:space-y-0">
      <span className="font-roboto text-base font-normal">
        {'Set Target Completion Date'}
      </span>
      <input
        type="date"
        onChange={(e) => onChange(e.target.value)}
        className="w-full rounded border border-gray-300 p-2 md:w-auto"
        value={value || ''}
      />
    </div>
  </div>
)

const ReturnToStep3Message = () => {
  const { formattedExerciseName, handleBackClick } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  return (
    <div
      onClick={handleBackClick}
      className="flex cursor-pointer items-center space-x-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-primary hover:text-primary-darker-75"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
        />
      </svg>
      <span className="font-roboto-light text-sm text-primary underline hover:text-primary-darker-75">
        {t('return_to_step_3')}
      </span>
    </div>
  )
}

export default DesignThinkingSectionThree
