import * as React from 'react'
import { format } from 'date-fns'
import { useTranslation } from '../../../hooks/useTranslation'
import { useIsMobile } from '../../../hooks/useMediaQuery'

export function PhoneCell({ row }) {
  const { t } = useTranslation('TitleCell')
  const savedRecruiter = row.original
  const isMobile = useIsMobile()

  const subTitle =
    isMobile && savedRecruiter.followup_on ? (
      <>
        <div>{t('follow_up')}:</div>
        <div className="rounded px-1.5 py-0.5 text-black data-[phase=overdue]:bg-[#FC7C5E] data-[phase=soon]:bg-[#FFE769]">
          {format(savedRecruiter.followup_on, 'P')}
        </div>
      </>
    ) : (
      <div className="line-clamp-1">{savedRecruiter.phone}</div>
    )

  return (
    <div className="flex flex-col gap-1 pr-2">
      <div className="text-normal line-clamp-1 font-bold">
        {savedRecruiter.title}
      </div>
      <div className="flex flex-row items-center gap-1 text-sm">{subTitle}</div>
    </div>
  )
}
