import React from 'react'

const CoverLettersListElement = ({ coverLetter }) => {
  const { right_nav_title, path } = coverLetter
  return (
    <div className="flex flex-col gap-2">
      <a
        href={path}
        className="ga-trackable flex flex-row text-white underline"
      >
        {right_nav_title}
      </a>
    </div>
  )
}

export default CoverLettersListElement
