import React from 'react'
import { usePersonalBrand } from '../PersonalBrandProvider'
import CoverLettersListElement from './CoverLettersListElement'
import { ChecklistIcon } from '../icons'
import { useTranslation } from '../../../hooks/useTranslation'

const CoverLettersList = () => {
  const { coverLetters } = usePersonalBrand()
  const { t } = useTranslation('cover_letters')

  if (!coverLetters || coverLetters.length === 0) {
    return (
      <div className="mt-8 flex flex-col gap-4">
        <h2 className="text-white">{t('my_cover_letters')}</h2>
        <a
          href="/story/cover_letters/new_ai_or_builder"
          className="flex !w-3/5 w-full items-center justify-center rounded-3xl bg-primary-lighter-15 px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
        >
          {t('create_a_cover_letter')}
        </a>
        <div>
          <ChecklistIcon className="h-12 w-12 stroke-white stroke-2" />
          <a
            href="https://www-yns-com.s3.amazonaws.com/checklists/cover_letter_checklist-yns.pdf"
            className="text-white underline"
          >
            {t('review_cover_letter_checklist')}
          </a>
        </div>
      </div>
    )
  } else {
    return (
      <div className="mt-8 flex flex-col gap-4">
        <h2 className="text-white">My Cover Letters</h2>
        {coverLetters.map((coverLetter, idx) => {
          return (
            <CoverLettersListElement
              key={`cover-letters-${idx}`}
              coverLetter={coverLetter}
              className={'col-span-2 md:col-span-1'}
            />
          )
        })}
      </div>
    )
  }
}

export default CoverLettersList
