import * as React from 'react'

import { useRecruiterSearch } from './RecruiterSearchProvider'
import { Button } from '../../../lib/ui/button'
import { useTranslation } from '../../../hooks/useTranslation'
import { SpinnerWhite } from '../../shared/Spinner'

export function PrimaryButton({ children, ...props }) {
  const { formDisabled } = useRecruiterSearch()
  const [fakeDisabled, setFakeDisabled] = React.useState(false)
  const fakeQuickSearch = () => {
    setFakeDisabled(true)
    setTimeout(() => {
      setFakeDisabled(false)
    }, 500)
  }

  const showSpinner = formDisabled || fakeDisabled

  return (
    <Button
      size="lg"
      className="w-full"
      disabled={formDisabled}
      type="submit"
      onClick={fakeQuickSearch}
      {...props}
    >
      {children}
      <SpinnerWhite show={showSpinner} className="pl-4" />
    </Button>
  )
}

export function ShowResultsButton({ ...props }) {
  const { formDisabled } = useRecruiterSearch()
  const { t } = useTranslation('ShowResultsButton')

  return (
    <Button type="submit" disabled={formDisabled} {...props}>
      {t('show_results')}
    </Button>
  )
}

export function ViewMoreButton() {
  const { handleViewMore, viewMoreButtonDisabled } = useRecruiterSearch()
  const { t } = useTranslation('ViewMoreButton')

  return (
    <Button
      className="w-full"
      onClick={handleViewMore}
      disabled={viewMoreButtonDisabled}
    >
      {t('view_more')}
      <SpinnerWhite show={viewMoreButtonDisabled} className="pl-4" />
    </Button>
  )
}
