import React from 'react'
import {
  PreviouslyWorkedWithCoach,
  IsCoachHelpful,
  CoachingGoals,
  GrowthExpectation,
  CoachNotes,
  MeetingTimes,
} from '../QuestionBank'
import { useProgramEvaluation } from '../ProgramEvaluationProvider'

const Professional = ({ form }) => {
  const { inplatform } = useProgramEvaluation()
  return (
    <>
      <PreviouslyWorkedWithCoach
        form={form}
        className={inplatform && 'md:col-span-6'}
      />
      <IsCoachHelpful form={form} className={inplatform && 'md:col-span-6'} />
      <CoachingGoals
        form={form}
        label="What are your goals for working with your INTOO career development coach? Please share them so that we can best support you."
      />
      <GrowthExpectation form={form} />
      <CoachNotes form={form} />
      <MeetingTimes form={form} />
    </>
  )
}
export default Professional
