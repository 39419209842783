import React, { useState, useEffect, useMemo } from 'react'
import { useExercise } from './ExerciseProvider'
import { useMediaQuery } from 'react-responsive'

const MotivationReflectionSectionFive = ({ data }) => {
  const { updateResponse } = useExercise()
  const sectionFiveData = useMemo(() => data[4] || {}, [data])
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const [inputs, setInputs] = useState([
    { action: '', date: '' },
    { action: '', date: '' },
    { action: '', date: '' },
  ])

  useEffect(() => {
    setInputs([
      {
        action: sectionFiveData.action0?.action || '',
        date: sectionFiveData.action0?.date || '',
      },
      {
        action: sectionFiveData.action1?.action || '',
        date: sectionFiveData.action1?.date || '',
      },
      {
        action: sectionFiveData.action2?.action || '',
        date: sectionFiveData.action2?.date || '',
      },
    ])
  }, [sectionFiveData])

  const handleDateChange = (index, date) => {
    const newInputs = [...inputs]
    newInputs[index].date = date
    setInputs(newInputs)
    updateResponse(4, `action${index}`, newInputs[index])
  }

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs]
    newInputs[index].action = value
    setInputs(newInputs)
    updateResponse(4, `action${index}`, newInputs[index])
  }

  return (
    <div
      className={`rounded-lg bg-white ${isMobile ? 'p-2' : 'p-8'} shadow-lg`}
    >
      <div className="space-y-8">
        <div className="space-y-6">
          {inputs.map((input, index) =>
            isMobile ? (
              <DataInputMobile
                key={index}
                action={input.action}
                value={input.date}
                onDateChange={(date) => handleDateChange(index, date)}
                onInputChange={(value) => handleInputChange(index, value)}
              />
            ) : (
              <DataInputDesktop
                key={index}
                action={input.action}
                value={input.date}
                onDateChange={(date) => handleDateChange(index, date)}
                onInputChange={(value) => handleInputChange(index, value)}
              />
            ),
          )}
        </div>
      </div>
    </div>
  )
}

const DataInputDesktop = ({ action, value, onDateChange, onInputChange }) => (
  <div className="flex flex-row items-center justify-between rounded-lg border border-[#D3D3D3] bg-[#F7FAFC] p-4">
    <input
      type="text"
      value={action || ''}
      onChange={(e) => {
        onInputChange(e.target.value)
      }}
      className="mr-3 w-1/2 rounded font-roboto text-base font-medium"
    />
    <div className="flex flex-row items-center space-x-4">
      <span className="font-roboto text-base font-normal">
        {'Set Target Completion Date'}
      </span>
      <input
        type="date"
        onChange={(e) => {
          onDateChange(e.target.value)
        }}
        className="w-auto rounded border border-gray-300 p-2"
        value={value || ''}
      />
    </div>
  </div>
)

const DataInputMobile = ({ action, value, onDateChange, onInputChange }) => (
  <div className="flex flex-col items-center justify-between rounded-lg border border-[#D3D3D3] bg-[#F7FAFC] p-4">
    <input
      type="text"
      value={action || ''}
      onChange={(e) => {
        onInputChange(e.target.value)
      }}
      className="mb-4 h-28 w-full rounded font-roboto text-base font-medium"
    />
    <div className="flex flex-col items-center space-y-2">
      <span className="font-roboto text-base font-normal">
        {'Set Target Completion Date'}
      </span>
      <input
        type="date"
        onChange={(e) => {
          onDateChange(e.target.value)
        }}
        className="w-full rounded border border-gray-300 p-2"
        value={value || ''}
      />
    </div>
  </div>
)

export default MotivationReflectionSectionFive
