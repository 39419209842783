import React, { createContext, useContext, useState } from 'react'
import { apiPatch } from '../../util/api'
import { useIsMobile, useIsDesktop } from '../../hooks/useMediaQuery'

const SkillsContext = createContext()

export const useSkills = () => {
  return useContext(SkillsContext)
}

const SkillsProvider = ({
  serialized_assessment_list,
  moduleName,
  userKeySkills,
  userDesiredSkills,
  skillsUpdatePath,
  skillsImagePath,
  children,
}) => {
  const [keySkills, setKeySkills] = useState(
    userKeySkills ? JSON.parse(userKeySkills) : {},
  )
  const [desiredSkills, setDesiredSkills] = useState(
    userDesiredSkills ? JSON.parse(userDesiredSkills) : {},
  )

  const extractSkillsArr = (skillsObj) => {
    const skills = []
    Object.entries(skillsObj).forEach(([categoryName, category]) => {
      Object.entries(category).forEach(([skillName, skill]) => {
        const ability = skill?.ability
        const like = skill?.like
        const newSkill = [categoryName, skillName, ability, like]
        skills.push(newSkill)
      })
    })
    return skills
  }

  const [skillsArr, setSkillsArr] = useState(extractSkillsArr(keySkills))
  const [desiredArr, setDesiredArr] = useState(extractSkillsArr(desiredSkills))
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const [disableSaveRankButton, setDisableSaveRankButton] = useState(false)

  const assessments = serialized_assessment_list

  const skillsPresent = !!skillsArr.length
  const desiredSkillsPresent = !!desiredArr.length

  const [activity, setActivity] = useState('graph_view')

  const promoteDesiredSkill = async (skill, ability, enjoyment) => {
    const skillCategory = skill[0]
    const skillName = skill[1]
    ability = parseInt(ability)
    enjoyment = parseInt(enjoyment)

    const newSkills = { ...keySkills }
    newSkills[skillCategory] = newSkills[skillCategory] || {}
    newSkills[skillCategory][skillName] = { ability, like: enjoyment }

    const newDesiredSkills = { ...desiredSkills }
    delete newDesiredSkills[skillCategory][skillName]
    if (Object.keys(newDesiredSkills[skillCategory]).length === 0) {
      delete newDesiredSkills[skillCategory]
    }

    const data = {
      document_part: {
        key_skills: JSON.stringify(newSkills),
        desired_skills: JSON.stringify(newDesiredSkills),
      },
    }
    try {
      const response = await apiPatch(skillsUpdatePath, data)
      if (response.status === 200) {
        const {
          data: {
            content: { desired_skills, key_skills },
          },
        } = response
        const newKeySkills = JSON.parse(key_skills)
        setKeySkills(newKeySkills)
        setSkillsArr(extractSkillsArr(newKeySkills))

        const newDesiredSkills = JSON.parse(desired_skills)
        setDesiredSkills(newDesiredSkills)
        const newDesiredArr = extractSkillsArr(newDesiredSkills)
        setDesiredArr(newDesiredArr)
        if (newDesiredArr.length > 0) {
          setSelectedDesiredSkill(newDesiredArr[0])
        }

        const updatedSkill = [skillCategory, skillName, ability, enjoyment]
        setSelectedSkill(updatedSkill)
        openGraphViewTab()
        return { success: true }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const saveSkillRank = async (skill, ability, enjoyment) => {
    const skillCategory = skill[0]
    const skillName = skill[1]
    const newSkills = { ...keySkills }
    ability = parseInt(ability)
    enjoyment = parseInt(enjoyment)
    newSkills[skillCategory] = newSkills[skillCategory] || {}
    const newSkill = newSkills[skillCategory][skillName]
    newSkill['ability'] = ability
    newSkill['like'] = enjoyment

    const data = {
      document_part: {
        key_skills: JSON.stringify(newSkills),
        desired_skills: JSON.stringify(desiredSkills),
      },
    }
    try {
      const response = await apiPatch(skillsUpdatePath, data)
      if (response.status === 200) {
        const {
          data: {
            content: { desired_skills, key_skills },
          },
        } = response
        setKeySkills(JSON.parse(key_skills))
        setDesiredSkills(JSON.parse(desired_skills))
        setSkillsArr(extractSkillsArr(JSON.parse(key_skills)))
        setDesiredArr(extractSkillsArr(JSON.parse(desired_skills)))

        const updatedSkill = [skillCategory, skillName, ability, enjoyment]
        setSelectedSkill(updatedSkill)
        return { success: true }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const openGraphViewTab = () => {
    setActivity('graph_view')
    setDisableSaveRankButton(false)
  }

  const graphViewActive = activity === 'graph_view'

  const openCardViewTab = () => {
    setActivity('card_view')
    setDisableSaveRankButton(false)
  }

  const cardViewActive = activity === 'card_view'

  const openDesiredSkillsTab = () => {
    setActivity('desired_skills_view')
    setDisableSaveRankButton(false)
  }

  const desiredSkillsActive = activity === 'desired_skills_view'

  const [selectedSkill, setSelectedSkill] = useState(
    skillsPresent ? skillsArr[0] : [],
  )

  const [selectedDesiredSkill, setSelectedDesiredSkill] = useState(
    desiredSkillsPresent ? desiredArr[0] : [],
  )

  const [skillsForSkillsDialog, setSkillsForSkillsDialog] = useState([])

  const [skillsDialogOpen, setSkillsDialogOpen] = useState(false)

  const addSkillstoSkillsDialog = (skills) => {
    setSkillsForSkillsDialog(skills)
    setSkillsDialogOpen(true)
  }

  const toggleSkillsDialog = () => setSkillsDialogOpen((prev) => !prev)

  const value = {
    moduleName,
    openGraphViewTab,
    graphViewActive,
    openCardViewTab,
    cardViewActive,
    skillsArr,
    skillsPresent,
    desiredArr,
    desiredSkillsPresent,
    saveSkillRank,
    promoteDesiredSkill,
    skillsImagePath,
    selectedSkill,
    setSelectedSkill,
    selectedDesiredSkill,
    setSelectedDesiredSkill,
    isMobile,
    isDesktop,
    disableSaveRankButton,
    setDisableSaveRankButton,
    addSkillstoSkillsDialog,
    skillsForSkillsDialog,
    skillsDialogOpen,
    toggleSkillsDialog,
    assessments,
    desiredSkillsActive,
    openDesiredSkillsTab,
  }

  return (
    <SkillsContext.Provider value={value}>{children}</SkillsContext.Provider>
  )
}

export default SkillsProvider
