import React from 'react'
import StartQuiz from './StartQuiz'
import Quiz from './Quiz'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { QuizProvider, useQuiz } from './QuizProvider'
import Results, {
  JobSearchQuizResults,
  JobSearchStrategyKnowledgeResults,
  SpotSoftSkillResults,
  PersonalBrandQuizResults,
  IdealWorkplaceEnvironmentResults,
  ResumeReadyToImpressResults,
} from './Results'
import CriticalThinkerResults from './CriticalThinkerResults'

const Index = ({ ...props }) => {
  return (
    <QuizProvider {...props}>
      <QuizComponents {...props} />
    </QuizProvider>
  )
}
export default Index

const QuizComponents = ({ locale }) => {
  const { documentId, started, showResults } = useQuiz()
  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope={'javascript.components.Quizzes'}
    >
      <div className="quiz flex justify-center border-l border-[#DDD] px-4 py-6">
        {showResults && <ResultsComponent />}
        {!started && !showResults && <StartQuiz quizExists={!!documentId} />}
        {started && !showResults && <Quiz />}
      </div>
    </TranslationProvider>
  )
}

const ResultsComponent = () => {
  const { quizName, resultsTasks } = useQuiz()

  if (quizName === 'Job Search') {
    return <JobSearchQuizResults tasks={resultsTasks} />
  }
  if (quizName === 'Personal Brand Iq') {
    return <PersonalBrandQuizResults tasks={resultsTasks} />
  }
  if (quizName === 'Job Search Strategy Knowledge') {
    return <JobSearchStrategyKnowledgeResults tasks={resultsTasks} />
  }
  if (quizName === 'Spot Soft Skill') {
    return <SpotSoftSkillResults tasks={resultsTasks} />
  }
  if (quizName === 'Ideal Workplace Environment') {
    return <IdealWorkplaceEnvironmentResults tasks={resultsTasks} />
  }
  if (quizName === 'Resume Ready to Impress') {
    return <ResumeReadyToImpressResults tasks={resultsTasks} />
  }
  if (quizName === 'Critical Thinker') {
    return <CriticalThinkerResults />
  }
}
