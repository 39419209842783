import * as React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { DataTable } from './DataTable'

export default function Page() {
  const { t } = useTranslation('Page')
  return (
    <div className="flex min-h-[67vh] flex-col gap-4">
      <div className="">
        <h1 className="py-2">{t('my_jobs')}</h1>
        <p>{t('explanation')}</p>
      </div>
      <DataTable />
    </div>
  )
}
