import * as React from 'react'
import ChevronDownIcon from '../JobSearchPage/ChevronDownIcon'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../lib/ui/form'
import { Input } from '../../../lib/ui/input'

export default function SearchDropdown({
  name,
  control,
  label,
  placeholder,
  description,
  options,
  ...props
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = React.useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full" ref={dropdownRef}>
          <FormLabel className="sr-only">{label}</FormLabel>
          <FormControl>
            <div className="relative">
              <div className="absolute left-2 top-2 z-10 text-muted-foreground md:left-4 md:top-3">
                <ChevronDownIcon />
              </div>
              <Input
                placeholder={placeholder}
                className="autocomplete w-full rounded-xl border-alto-300 bg-intoo-haze pl-10 focus-visible:ring-intoo-blue-medium md:h-12 md:pl-14 md:text-lg"
                {...field}
                {...props}
                onClick={toggleDropdown}
              />
              {isOpen && (
                <ul className="absolute z-20 mt-1 max-h-60 w-full min-w-[250px] overflow-y-auto rounded-xl border border-alto-300 border-input bg-background bg-intoo-haze">
                  {options.map((option, index) => (
                    <li
                      key={index}
                      className="cursor-pointer p-2 hover:bg-accent"
                      onClick={() => {
                        field.onChange(option)
                        setIsOpen(false)
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </FormControl>
          <FormDescription className="sr-only">{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
