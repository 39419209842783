import React from 'react'

const getStrokeColor = (white, black) =>
  white ? 'white' : black ? 'black' : 'black'

export const No = ({ white, black }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      role="img"
      aria-label="No icon"
    >
      <path
        d="M24.0552 22.9551C26.2634 20.8453 27.5039 17.9838 27.5039 15.0001C27.5039 12.0164 26.2634 9.15487 24.0552 7.04507C21.8471 4.93527 18.8522 3.75 15.7294 3.75C12.6066 3.75 9.61166 4.93527 7.4035 7.04507M24.0552 22.9551C21.8471 25.0649 18.8522 26.2501 15.7294 26.2501C12.6066 26.2501 9.61166 25.0649 7.4035 22.9551C5.19535 20.8453 3.95482 17.9838 3.95482 15.0001C3.95482 12.0164 5.19535 9.15487 7.4035 7.04507M24.0552 22.9551L7.4035 7.04507"
        stroke={getStrokeColor(white, black)}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Yes = ({ white, black }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      role="img"
      aria-label="Yes icon"
    >
      <path
        d="M11.25 15.9375L14.0625 18.75L18.75 12.1875M26.25 15C26.25 16.4774 25.959 17.9403 25.3936 19.3052C24.8283 20.6701 23.9996 21.9103 22.955 22.955C21.9103 23.9996 20.6701 24.8283 19.3052 25.3936C17.9403 25.959 16.4774 26.25 15 26.25C13.5226 26.25 12.0597 25.959 10.6948 25.3936C9.3299 24.8283 8.08971 23.9996 7.04505 22.955C6.00039 21.9103 5.17172 20.6701 4.60636 19.3052C4.04099 17.9403 3.75 16.4774 3.75 15C3.75 12.0163 4.93526 9.15483 7.04505 7.04505C9.15483 4.93526 12.0163 3.75 15 3.75C17.9837 3.75 20.8452 4.93526 22.955 7.04505C25.0647 9.15483 26.25 12.0163 26.25 15Z"
        stroke={getStrokeColor(white, black)}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
