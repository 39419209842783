import React from 'react'
import { ShortCoursesProvider } from './ShortCourseProvider'
import CourseOverview from './CourseOverview'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import SubNav from './Subnav'

const ShortCoursesInner = () => {
  return (
    <>
      <CourseOverview />
      <SubNav />
    </>
  )
}

const ShortCourses = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale="en"
      translations={translations}
      scope={'javascript.components.ShortCourses'}
    >
      <ShortCoursesProvider {...props}>
        <ShortCoursesInner />
      </ShortCoursesProvider>
    </TranslationProvider>
  )
}
export default ShortCourses
