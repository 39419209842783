import React, { useState } from 'react'
import { useExercise } from './ExerciseProvider'
import { useTranslation } from '../../hooks/useTranslation'

const AddAdditionalIdeas = () => {
  const {
    formattedExerciseName,
    trackContent,
    exerciseIndex,
    updateResponse,
    dispatch,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const thinkOutsideBox = t('think_outside_box')
  const savedIdeas = trackContent[exerciseIndex]?.additional_ideas || []
  const [textBoxes, setTextBoxes] = useState(
    savedIdeas.length > 0 ? savedIdeas.map((idea) => idea.idea) : [''],
  )

  const handleChange = (index, event) => {
    const newTextBoxes = [...textBoxes]
    newTextBoxes[index] = event.target.value
    setTextBoxes(newTextBoxes)

    const existingIdea = savedIdeas[index] || {}
    dispatch({
      type: 'UPDATE_IDEA',
      payload: {
        index,
        idea: event.target.value,
        completionDate: existingIdea.completionDate,
      },
    })
    updateResponse(
      exerciseIndex,
      'additional_ideas',
      newTextBoxes,
      existingIdea?.completionDate,
    )
  }

  const addAnotherTextBox = () => {
    const newTextBoxes = [...textBoxes, '']
    setTextBoxes(newTextBoxes)
    dispatch({ type: 'ADD_IDEA', payload: { idea: '' } })
    updateResponse(exerciseIndex, 'additional_ideas', newTextBoxes)
  }

  const removeTextBox = (index) => {
    const newTextBoxes = textBoxes.filter((_, i) => i !== index)
    const existingIdea = savedIdeas[index] || {}
    setTextBoxes(newTextBoxes)
    dispatch({ type: 'DELETE_IDEA', payload: index })
    updateResponse(
      exerciseIndex,
      'additional_ideas',
      newTextBoxes,
      existingIdea?.completionDate,
    )
  }

  return (
    <>
      <p className="mt-6 font-roboto text-lg font-medium">{thinkOutsideBox}</p>
      <div className="additional-ideas space-y-4">
        {textBoxes.map((idea, index) => (
          <AdditionalIdea
            key={index}
            idea={idea}
            onButtonClick={() => removeTextBox(index)}
            onChange={(e) => handleChange(index, e)}
          />
        ))}
        <button
          onClick={addAnotherTextBox}
          className="font-roboto-light mt-4 text-sm font-medium text-blue-600 hover:text-blue-800 focus:underline focus:outline-none"
        >
          + Add Another
        </button>
      </div>
    </>
  )
}

export default AddAdditionalIdeas

const AdditionalIdea = ({ idea, onButtonClick, onChange }) => {
  return (
    <div className="flex items-center space-x-2">
      <textarea
        className="flex-1 rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
        onChange={onChange}
        value={idea}
      />
      <button
        onClick={onButtonClick}
        className="font-roboto-light text-sm font-medium text-red-600 hover:text-red-800 focus:underline focus:outline-none"
      >
        Delete
      </button>
    </div>
  )
}
