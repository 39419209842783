import * as React from 'react'

import { useRecruiterSearch } from './RecruiterSearchProvider'
import Sanitize from '../Sanitize'
import { useTranslation } from '../../../hooks/useTranslation'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import CheckCircleIcon from '../JobSearchPage/CheckCircleIcon'

export default function RecruiterDetails() {
  return (
    <div className="flex flex-col gap-4 p-4">
      <RecruiterDetailsTop />
      <RecruiterDetailsBottom />
    </div>
  )
}

export function RecruiterDetailsTop() {
  const {
    selectedRecruiter: recruiter,
    debug,
    queryStatusMessage,
    isSavedRecruiter,
  } = useRecruiterSearch()
  const isMobile = useIsMobile()
  if (!recruiter) return null

  return (
    <>
      <div className={`bg-[#08467C] text-white ${isMobile ? 'p-4' : 'p-0'}`}>
        {!isMobile && (
          <div className="relative flex flex-row justify-between">
            <div className="absolute right-0 top-0">
              {isSavedRecruiter(recruiter) ? (
                <SavedToMyRecruiters />
              ) : (
                <AddToMyRecruitersButton
                  recruiter={recruiter}
                  variant="icon"
                  includeText
                />
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-extrabold">{recruiter.name}</h2>

          <div className="flex items-center text-base font-normal">
            <div>{recruiter.city_state}</div>
          </div>

          <div className={`inline-flex gap-4 ${isMobile ? 'flex-col' : ''}`}>
            <FirmType recruiter={recruiter} />
            <SalaryTag salary={recruiter.salary} />
          </div>

          {isMobile && (
            <div className="mt-4 flex">
              {isSavedRecruiter(recruiter) ? (
                <SavedToMyRecruiters />
              ) : (
                <AddToMyRecruitersButton
                  recruiter={recruiter}
                  variant="icon"
                  includeText
                />
              )}
            </div>
          )}
        </div>

        {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
      </div>
    </>
  )
}

export function RecruiterDetailsBottom() {
  const { selectedRecruiter: recruiter } = useRecruiterSearch()
  const { t } = useTranslation('RecruiterDetails')
  const currentYear = new Date().getFullYear()
  const isMobile = useIsMobile()

  if (!recruiter) return null

  return (
    <>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <h2 className="!mr-16 w-40 text-base font-medium text-black">
          {t('physical_address')}:
        </h2>
        <p className="!mb-0 text-base font-normal">
          {recruiter.physical_address}
        </p>
      </div>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <h2 className="!mr-16 w-40 text-base font-medium text-black">
          {t('mailing_address')}:
        </h2>
        <p className="!mb-0 text-base font-normal">
          {recruiter.mailing_address}
        </p>
      </div>

      {recruiter.phone !== '--' && (
        <div
          className={`mb-${isMobile ? '0' : '5'} flex ${
            isMobile ? 'flex-col' : ''
          }`}
        >
          <h2 className="!mr-16 w-40 text-base font-medium text-black">
            {t('phone_number')}:
          </h2>
          <p className="!mb-0 text-base font-normal">{recruiter.phone}</p>
        </div>
      )}
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <h2 className="!mr-16 w-40 text-base font-medium text-black">
          {t('email')}:
        </h2>
        <p className="!mb-0 text-base font-normal text-blue-600 underline">
          {recruiter.email}
        </p>
      </div>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <h2 className="!mr-16 w-40 text-base font-medium text-black">
          {t('website')}:
        </h2>
        <p className="!mb-0 text-base font-normal text-blue-600 underline">
          {recruiter.url}
        </p>
      </div>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <h2 className="!mr-16 w-40 text-base font-medium text-black">
          {t('min_salary_level_assignments')}:
        </h2>
        <p className="!mb-0 text-base font-normal">
          {recruiter.minimum_salary_value}
        </p>
      </div>

      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <h2 className="text-base font-medium text-black">{t('description')}</h2>
        <div className="text-base font-normal">
          <Sanitize html={recruiter.description} addMarkup />
        </div>
      </div>

      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <h2 className="text-base font-medium text-black">{t('industries')}</h2>
        <p className="text-base font-normal">{recruiter.industries}</p>
      </div>
      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <h2 className="text-base font-medium text-black">{t('specialties')}</h2>
        <p className="text-base font-normal">{recruiter.specialties}</p>
      </div>

      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <h2 className="text-base font-medium text-black">{t('contacts')}</h2>
        <ul className="ml-3 list-inside list-disc space-y-2 text-base font-normal">
          {recruiter.contacts.map((contact, index) => (
            <li key={index}>
              {contact.full_name} - {contact.title} -{' '}
              <a
                href={`mailto:${contact.email}`}
                className="text-blue-600 underline"
              >
                {contact.email}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h2 className="text-base font-medium text-black">{t('source')}</h2>
        <p className="text-base font-normal">
          Custom Databanks, Inc - Copyright {currentYear}
        </p>
      </div>
    </>
  )
}

const AddToMyRecruitersButton = ({ recruiter }) => {
  const { addToMyRecruiters } = useRecruiterSearch()

  return (
    <button
      onClick={() => addToMyRecruiters(recruiter)}
      className="flex rounded-full border border-white bg-transparent p-2 text-base font-medium text-white"
    >
      <PlusSignIcon /> Add to My Recruiters
    </button>
  )
}

const PlusSignIcon = () => {
  return (
    <div className="mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 4.5V19.5M19.5 12H4.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

const TagIcon = () => {
  return (
    <div className="ml-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.568 3H5.25C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25V9.568C3 10.165 3.237 10.738 3.659 11.159L13.24 20.74C13.939 21.439 15.02 21.612 15.847 21.07C17.9286 19.7066 19.7066 17.9286 21.07 15.847C21.612 15.02 21.439 13.939 20.74 13.24L11.16 3.66C10.951 3.45077 10.7029 3.28478 10.4297 3.17154C10.1565 3.05829 9.86371 3 9.568 3Z"
          stroke="#08467C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6H6.008V6.008H6V6Z"
          stroke="#08467C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

const FirmType = ({ recruiter }) => {
  const { t } = useTranslation('RecruiterFirmType')
  const permanent = t('permanent')
  const temporary = t('temporary')
  const firmType =
    recruiter.firm_type == 'Temporary Staffing' ? temporary : permanent
  return (
    <div className="flex w-fit items-center rounded-xl bg-white px-4 py-2 font-roboto text-base font-medium text-[#08467C]">
      {firmType}
      <TagIcon />
    </div>
  )
}

const SalaryTag = ({ salary }) => {
  const { t } = useTranslation('SalaryTag')
  const isMobile = useIsMobile()

  const getSalaryRange = (salary) => {
    const numericSalary = parseInt(salary.replace(/[$,]/g, ''), 10)
    if (numericSalary < 50000) return 'under_50k'
    if (numericSalary < 75000) return '50k_75k'
    if (numericSalary < 100000) return '75k_100k'
    if (numericSalary < 125000) return '100k_125k'
    return '125k_plus'
  }

  const salaryRangeKey = getSalaryRange(salary)
  const translatedSalary = t(
    isMobile ? `${salaryRangeKey}_mobile` : salaryRangeKey,
  )

  return (
    <div
      className={`flex items-center rounded-xl bg-white px-4 py-2 font-roboto text-base font-medium text-[#08467C] ${
        isMobile ? 'w-fit' : ''
      }`}
    >
      {translatedSalary}
      <TagIcon />
    </div>
  )
}
export function SavedToMyRecruiters() {
  const { t } = useTranslation('RecruiterDetails')
  return (
    <div
      className="flex cursor-default items-center gap-0"
      title={t('applied_with_intoo')}
    >
      <CheckCircleIcon source="SavedToMyRecruiters" />
      <span className="text-sm font-medium text-white">
        {t('saved_to_my_recruiters')}
      </span>
    </div>
  )
}
