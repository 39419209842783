import React from 'react'
import Page from './Page'
import { Button } from '../../lib/ui/button'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import { ExerciseIcon } from './icons'
import { useIsDesktop } from '../../hooks/useMediaQuery'

const Exercise = () => {
  const { formattedExerciseName, saveExercise, exercisePartId } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pagesInfo = t('pages')
  const pagesArr = Object.values(pagesInfo)
  const numPages = pagesArr.length
  const isDesktop = useIsDesktop()

  // const handleLeavePage = () => {
  //   localStorage.removeItem(`${exercisePartId}:started`)
  //   localStorage.removeItem(`${exercisePartId}:results`)
  //   localStorage.removeItem('idx')
  //   saveExercise()
  // }

  return (
    <div className="container relative flex flex-col">
      {isDesktop && (
        <ExerciseIcon
          source="ExerciseSteps"
          className="absolute right-12 top-12 h-20 w-20"
        />
      )}
      <div className="flex">
        {pagesArr.map((page, idx) => (
          <Page
            key={`page-${idx}`}
            idx={idx}
            pageInfo={page}
            numPages={numPages}
          />
        ))}
      </div>
    </div>
  )
}
export default Exercise
