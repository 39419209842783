import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useTranslation } from '../../../hooks/useTranslation'

export const useJobSearchForm = ({
  urlSearchParams,
  lockParamString,
  noResultQuery,
  setPagesToShow,
  setPrefetchedPages,
  saved_params,
}) => {
  const { t } = useTranslation('useJobSearchForm')

  const FormSchema = z.object({
    what: z.string().min(1, {
      message: t('validation.enter_job_title'),
    }),
    // TODO: Fix this validation
    // .refine((value) => noResultQuery === '' || value !== noResultQuery, {
    //   message: t('validation.enter_different_job_title'),
    // }),
    where: z.string().optional(),
    sort: z.string().optional(),
    filter_experience_level: z.array(z.string()).optional(),
    filter_job_type: z.array(z.string()).optional(),
    filter_employment_type: z.array(z.string()).optional(),
    filter_date_posted: z.array(z.string()).optional(),
    filter_job_bank_jobs: z.boolean().optional(),
  })

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      what: urlSearchParams.get('what') || saved_params.what || '',
      where: urlSearchParams.get('where') || saved_params.where || '',
      sort: urlSearchParams.get('sort') || saved_params.sort || '',
      filter_experience_level:
        urlSearchParams.getAll('filter_experience_level[]') || [],
      filter_job_type: urlSearchParams.getAll('filter_job_type[]') || [],
      filter_employment_type:
        urlSearchParams.getAll('filter_employment_type[]') || [],
      filter_date_posted: urlSearchParams.getAll('filter_date_posted[]') || [],
      filter_job_bank_jobs:
        urlSearchParams.get('filter_job_bank_jobs') === 'true' || false,
    },
  })

  const onSubmit = async (_data) => {
    lockParamString()
    setPagesToShow(1)
    setPrefetchedPages(false)
  }

  return {
    form,
    onSubmit,
    noResultQuery,
  }
}
