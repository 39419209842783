import React from 'react'
import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Page from './Page'
import { SavedRecruitersProvider } from './SavedRecruitersProvider'

const queryClient = new QueryClient()

const SavedRecruitersPage = ({ locale, ...props }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider
        locale={locale}
        translations={translations}
        scope={'javascript.components.job_search.SavedRecruitersPage'}
      >
        <SavedRecruitersProvider {...props}>
          <div className="fix-legacy-hack flex flex-col border-l border-alto-200">
            <div className="md:px-[2.5rem] md:py-[2.5rem]">
              <Page />
            </div>
          </div>
        </SavedRecruitersProvider>
      </TranslationProvider>
    </QueryClientProvider>
  )
}
export default SavedRecruitersPage
