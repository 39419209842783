import React, { useEffect } from 'react'
import { PrimaryExerciseButton, BackExerciseButton } from './Buttons'
import { useIsDesktop, useIsMobile } from '../../hooks/useMediaQuery'
import { useExercise } from './ExerciseProvider'
import Layout from './Layout'

const Page = ({ pageInfo, numPages, idx }) => {
  const { saveExercise, setExerciseIndex, setStarted, exerciseIndex } =
    useExercise()
  const page = pageInfo.page
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [exerciseIndex])

  const handleBackClick = () => {
    if (exerciseIndex === 0) {
      setStarted(false)
    } else {
      setExerciseIndex((prev) => prev - 1)
    }
  }

  const handleNextClick = () => {
    const showConclusionPage = exerciseIndex === numPages - 1
    if (showConclusionPage) {
      saveExercise(showConclusionPage)
    } else {
      setExerciseIndex((prev) => prev + 1)
    }
  }

  return (
    exerciseIndex === idx && (
      <div className="flex flex-col">
        <h1 className="text-2xl sm:!my-8">{page}</h1>
        <div>
          <Layout pageInfo={pageInfo} section={exerciseIndex} />
        </div>
        <div
          className={`flex gap-4 self-end sm:gap-6 ${
            isMobile ? 'self-center' : ''
          }`}
        >
          <BackExerciseButton
            onClick={handleBackClick}
            className={isMobile ? 'mb-5' : ''}
          >
            Back
          </BackExerciseButton>
          <div className="flex flex-col items-center gap-[10px]">
            <PrimaryExerciseButton
              onClick={handleNextClick}
              className={isMobile ? 'mb-5' : ''}
            >
              Continue
            </PrimaryExerciseButton>
            {isDesktop && (
              <div>
                {exerciseIndex + 1} of {numPages}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default Page
