import React, { createContext, useContext, useState } from 'react'
import usePersistState from '../../hooks/usePersistState'
import useTranslation from '../shared/TranslationProvider'

const ShortCoursesContext = createContext()

const useShortCourses = () => {
  return useContext(ShortCoursesContext)
}

export const ShortCoursesProvider = ({ course, children }) => {
  const [currentCourse, setCurrentCourse] = useState(course)
  const slug = currentCourse.slug
  const { t } = useTranslation(`${slug}.overview`)
  const subNavItems = t('subnav')
  const [activeSubNav, setActiveSubNav] = usePersistState(
    subNavItems[0].slug,
    'shortCoursesSubnav',
  )

  const value = {
    currentCourse,
    setCurrentCourse,
    activeSubNav,
    setActiveSubNav,
    slug,
  }

  return (
    <ShortCoursesContext.Provider value={value}>
      {children}
    </ShortCoursesContext.Provider>
  )
}

export default useShortCourses
