import React, { useState } from 'react'
import Progress from './Progress'
import Avatar from './Avatar'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { useTranslation } from '../../hooks/useTranslation'
import {
  useMultipleChoiceQuizScore,
  useCalculateMultipleChoiceScore,
  useYesNoQuizScore,
  useJobSearchStrategyKnowledgeScore,
  useSpotSoftSkillQuizScore,
  useSelfAssessmentQuizScore,
} from '../JobSearchQuiz/useScoreCalculator'
import { PrimaryQuizButton, BackQuizButton } from './Buttons'
import { DownArrow, RightArrow } from './Icons/Arrows'
import Task from './Task'
import { useQuiz } from './QuizProvider'
import { cn } from '../../lib/utils'

const Results = ({ className, children, isScrollable }) => {
  const { setShowResults, quizPartId } = useQuiz()
  const [disabledButton, setDisabledButton] = useState(false)

  const handleNextClick = () => {
    localStorage.removeItem(`${quizPartId}:started`)
    localStorage.removeItem(`${quizPartId}:results`)
    localStorage.removeItem('idx')
    setDisabledButton(true)
    window.location.pathname = '/'
  }

  const outerDivClassName = isScrollable
    ? cn('container mt-6 flex min-h-screen flex-col', className)
    : cn('container mt-6 flex flex-col', className)

  const innerDiv = isScrollable ? (
    <div className="flex flex-grow flex-col overflow-y-auto pb-6 sm:justify-evenly">
      {children}
    </div>
  ) : (
    <div className="flex-grow">{children}</div>
  )

  return (
    <div className={outerDivClassName}>
      <Progress completed />
      {innerDiv}
      <div className="mt-auto flex gap-4 self-end sm:gap-6">
        <BackQuizButton onClick={() => setShowResults(false)}>
          Back
        </BackQuizButton>
        <div className="flex flex-col items-center gap-2">
          <PrimaryQuizButton
            onClick={handleNextClick}
            disabled={disabledButton}
          >
            My Track
          </PrimaryQuizButton>
        </div>
      </div>
    </div>
  )
}

export default Results

export const LinkifiedCopy = ({ copy, link }) => {
  const copySplit = copy.split('[link]')
  const { linkPath, linkText } = link
  const copy1 = copySplit[0]
  const copy2 = copySplit[1]
  return (
    <p>
      {copy1}
      {copy2 && (
        <a className="!underline" href={linkPath}>
          {linkText}
        </a>
      )}
      {copy2}
    </p>
  )
}

export const PersonalBrandQuizResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const link1 = 'Building a Personal Brand in the Digital Age'
  const link2 = 'Personal Branding in the Digital Age'

  const linkMap = {
    1: link1,
    2: link1,
    3: link2,
    4: link1,
  }

  const breakPoints = [7, 4, 2]
  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
  )

  const header = t(`results.${scoreSection}.header`)
  const copy = t(`results.${scoreSection}.copy`, { score })

  const linkText = linkMap[scoreSection]

  const link = {
    linkPath: '/personal_brand/resources/article/building-personal-brand',
    linkText: linkText,
  }

  return (
    quizName === 'Personal Brand Iq' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            {scoreSection !== 4 && <h1 className="!text-[23px]">{header}</h1>}
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              {copy.map((copy, idx) => (
                <LinkifiedCopy key={`copy-${idx}`} copy={copy} link={link} />
              ))}
              <p>{t(`results.congrats`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export const SpotSoftSkillResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const score = useSpotSoftSkillQuizScore(trackContent, t('questions'))

  return (
    quizName === 'Spot Soft Skill' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              <p>{t(`results.great_job`)}</p>
              <div className="mt-4 grid grid-cols-3 gap-4">
                {t('results.skills')
                  .split(',')
                  .map((skill, index) => (
                    <p key={index}>{skill}</p>
                  ))}
              </div>
              <p className="mt-4 font-bold">{t(`results.time_to_reflect`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
        <SpotSoftSkillCoachTips />
      </Results>
    )
  )
}

const SpotSoftSkillCoachTips = () => {
  const { formattedQuizName, quizName } = useQuiz()
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)

  return (
    quizName === 'Spot Soft Skill' && (
      <div className="flex pb-6 sm:justify-start">
        <div className="ml-10 mt-20 flex h-96 flex-col sm:w-9/12">
          <h2>{t('coach_tips.header')}</h2>

          <div className="mt-8 grid">
            <p>{t('coach_tips.intro')}</p>

            <ul className="mt-8">
              {t('coach_tips.essential_skills').map((skills_list, index) => (
                <li key={index} className="ml-4·list-disc">
                  <strong>{skills_list.title}</strong>: {skills_list.desc}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-8 grid">
            {t('coach_tips.paragraphs').map((paragraph, index) => (
              <div key={index} className="mt-8 grid">
                <p>
                  <strong>{paragraph.title}</strong>
                </p>
                <p>{paragraph.p} </p>
              </div>
            ))}
          </div>

          <div className="mt-8 grid">
            <p>{t('coach_tips.star_method.p1')}</p>
            <ul className="mt-8">
              {t('coach_tips.star_method.starlist').map((starlist, index) => (
                <li key={index} className="ml-4·list-disc">
                  <strong>{starlist.title}</strong>: {starlist.desc}
                </li>
              ))}
            </ul>
            <p className="mt-2">{t('coach_tips.star_method.p2')}</p>
            <p className="mt-2">{t('coach_tips.star_method.p3')}</p>
            <p className="mt-2">{t('coach_tips.star_method.p4')}</p>
          </div>

          <div className="mt-8 grid">
            <p>{t('coach_tips.continuous_improvement.p1')}</p>
            <ul className="mt-8">
              {t('coach_tips.continuous_improvement.list').map(
                (list, index) => (
                  <li key={index} className="ml-4·list-disc">
                    <strong>{list.title}</strong>: {list.desc}
                  </li>
                ),
              )}
            </ul>
            <p className="mt-2">{t('coach_tips.continuous_improvement.p2')}</p>
            <p className="mt-2">{t('coach_tips.continuous_improvement.p3')}</p>
            <p className="mt-2">{t('coach_tips.continuous_improvement.p4')}</p>
          </div>
        </div>
      </div>
    )
  )
}

export const JobSearchQuizResults = ({ tasks }) => {
  const {
    quizName,
    formattedQuizName,
    resultsPageAvatar,
    trackContent,
    numChoices,
  } = useQuiz()
  const avatar = resultsPageAvatar
  const resultsTasks = Object.values(tasks)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const [scoreSection, variable] = useYesNoQuizScore(
    trackContent,
    numChoices,
    t('questions'),
  )

  const header = t(`results.${scoreSection}.header`)
  const copy =
    scoreSection === 2
      ? t(`results.${scoreSection}.copy`, { numAs: variable })
      : t(`results.${scoreSection}.copy`, { score: variable })

  return (
    quizName === 'Job Search' && (
      <Results isScrollable={true}>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 sm:w-9/12">
            <h1 className="!text-[23px]">{header}</h1>
            <p className="mt-4">{copy}</p>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
        <div className="divide-y divide-solid divide-[#B9B9B9] rounded-[10px] border-[1.5px] border-solid border-[#08467C]">
          <div
            style={{ fontFamily: 'gotham_boldregular' }}
            className="flex flex-col items-center py-4 sm:flex-row sm:pl-16"
          >
            <div className="sm:w-2/5">Remaining areas of preparation</div>
            {!isDesktop && <DownArrow className="py-2" />}
            {isDesktop && <RightArrow className="sm:w-1/5" />}
            <div className="flex flex-col items-center sm:w-2/5">
              <div>Tasks added to My Track</div>
            </div>
          </div>
          {resultsTasks.map((task, idx) => {
            const { task: taskText, links } = task
            return (
              <Task
                key={`task-${idx}`}
                className={`flex flex-col items-center py-4 sm:flex-row sm:pl-16`}
                task={taskText}
                links={links}
                showArrow={isDesktop}
              />
            )
          })}
        </div>
      </Results>
    )
  )
}

export const JobSearchStrategyKnowledgeResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { score, section, proficiency, t } = useJobSearchStrategyKnowledgeScore(
    trackContent,
    formattedQuizName,
  )

  const copy = t(`results.${section}`, { score })

  return (
    quizName === 'Job Search Strategy Knowledge' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-2 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { proficiency })}</h2>
            <div className="mt-4">
              <p>{copy}</p>
              <br />
              <p>{t(`results.all`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export const IdealWorkplaceEnvironmentResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { feedbackKey, selfAssessmentResponse } =
    useSelfAssessmentQuizScore(trackContent)
  const cultureTypes = [
    'collaborative',
    'competitive',
    'innovative',
    'purpose_driven',
    'structured',
  ]
  const { t } = useTranslation(formattedQuizName)
  const additionalTips = t('results.all_culture_types.additional_tips')
  const finalThoughtsContent = t(
    'results.all_culture_types.final_thoughts.content',
  )
  const numberedList = t('results.numbered_list')

  return (
    quizName === 'Ideal Workplace Environment' && (
      <Results>
        <div className="flex flex-grow flex-col overflow-y-auto py-6 sm:justify-around">
          <div className="mt-2 flex flex-col gap-4 sm:w-9/12">
            <p>{t(`results.p1`)}</p>
            <p>{t(`results.p2`)}</p>
            <h3 className="font-bold">{t(`results.numbered_list_title`)}</h3>
            <NumberedList items={numberedList} />
            {selfAssessmentResponse === 'clearResult' && (
              <ClearResult feedbackKey={feedbackKey} t={t} />
            )}
            {selfAssessmentResponse === 'splitResult' && (
              <SplitResult feedbackKey={feedbackKey} t={t} />
            )}
            {selfAssessmentResponse === 'allSameScore' && (
              <AllSameScore cultureTypes={cultureTypes} t={t} />
            )}
            <AdditionalTipsAndFinalThoughts
              additionalTips={additionalTips}
              finalThoughtsContent={finalThoughtsContent}
              t={t}
            />
          </div>
          {/* {isDesktop && <Avatar src={avatar} />} */}
        </div>
      </Results>
    )
  )
}

export const ResumeReadyToImpressResults = () => {
  const {
    formattedQuizName,
    resultsPageAvatar,
    trackContent,
    quizName,
    hasResumeReview,
  } = useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const [score, numCorrect] = useCalculateMultipleChoiceScore(
    trackContent,
    t('questions'),
  )
  const scoreSection = numCorrect > 11 ? 1 : numCorrect < 8 ? 3 : 2
  const copy = t(`results.${scoreSection}.copy`)
  const link = {
    linkPath: '/resume/resources/article/15-great-resume-tips',
    linkText: '15 Great Resume Tips',
  }
  const contentValues = Object.values(trackContent)

  return (
    quizName === 'Resume Ready to Impress' && (
      <Results isScrollable={true}>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              <div className="mt-4 grid grid-cols-1 gap-2">
                {copy.map((copy, idx) => (
                  <LinkifiedCopy key={`copy-${idx}`} copy={copy} link={link} />
                ))}
                {hasResumeReview && scoreSection === 3 && (
                  <p>{t(`results.offer_resume_review`)}</p>
                )}
              </div>

              {numCorrect < 15 && (
                <div className="mt-2 grid grid-cols-1 gap-1">
                  <p>
                    <strong>{t(`results.${scoreSection}.incorrect`)}</strong>
                  </p>
                  {contentValues.map((response, idx) => {
                    if (
                      t(
                        `questions.${idx + 1}.options.${response}.feedback_key`,
                      ) === 'no_response'
                    ) {
                      return (
                        <div key={`content-${idx + 1}`} className="mt-1">
                          <p>
                            <strong>
                              {t(`questions.${idx + 1}.question`)}
                            </strong>
                          </p>
                          <p>
                            {t(`questions.${idx + 1}.feedback.no_response`)[0]}
                          </p>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

const NumberedList = ({ items }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <ol className="ml-6 list-decimal">
      {isArray(items).map((item, index) => (
        <li key={index} className={index !== items.length - 1 ? 'mb-4' : ''}>
          <strong>{item.title}</strong>: {item.description}
        </li>
      ))}
    </ol>
  )
}

const ClearResult = ({ feedbackKey, t }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <>
      <h3 className="my-3 font-bold">
        {t(`results.clear_result`)}{' '}
        {t(`results.culture_types.${feedbackKey}`) + '.'}
      </h3>
      <h4 className="my-3 font-bold">
        {t(`results.understanding_culture_type.${feedbackKey}.title`)}
      </h4>
      <p>{t(`results.understanding_culture_type.${feedbackKey}.p1`)}</p>
      <ul className="ml-6 list-disc">
        {isArray(
          t(
            `results.understanding_culture_type.${feedbackKey}.characteristics`,
          ),
        ).map((characteristic, index) => (
          <li key={index}>{characteristic}</li>
        ))}
      </ul>
      {isArray(
        t(`results.understanding_culture_type.${feedbackKey}.conclusion`),
      ).map((conclusion, index) => (
        <p key={index}>{conclusion}</p>
      ))}
    </>
  )
}

const SplitResult = ({ feedbackKey, t }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <>
      <h3 className="my-3 font-bold">
        {t(`results.split_result`)}{' '}
        {feedbackKey.length > 1
          ? feedbackKey
              .slice(0, -1)
              .map((key) => t(`results.culture_types.${key}`))
              .join(', ') +
            ` ${t('results.and')} ` +
            t(`results.culture_types.${feedbackKey[feedbackKey.length - 1]}`) +
            '.'
          : t(`results.culture_types.${feedbackKey[0]}`) + '.'}
      </h3>
      {feedbackKey.map((key) => (
        <div key={key}>
          <h4 className="my-3 font-bold">
            {t(`results.understanding_culture_type.${key}.title`)}
          </h4>
          <p>{t(`results.understanding_culture_type.${key}.p1`)}</p>
          <ul className="mb-4 ml-6 list-disc">
            {isArray(
              t(`results.understanding_culture_type.${key}.characteristics`),
            ).map((characteristic, index) => (
              <li key={index}>{characteristic}</li>
            ))}
          </ul>
          {isArray(
            t(`results.understanding_culture_type.${key}.conclusion`),
          ).map((conclusion, index, array) => (
            <p key={index} className={index !== array.length - 1 ? 'mb-4' : ''}>
              {conclusion}
            </p>
          ))}
        </div>
      ))}
    </>
  )
}

const AllSameScore = ({ cultureTypes, t }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <>
      <h3 className="my-3 font-bold">{t(`results.all_same_score`)}</h3>
      {cultureTypes.map((type) => (
        <div key={type}>
          <h3 className="my-3 font-bold">
            {t(`results.understanding_culture_type.${type}.title`)}
          </h3>
          <p>{t(`results.understanding_culture_type.${type}.p1`)}</p>
          <ul className="mb-4 ml-6 list-disc">
            {isArray(
              t(`results.understanding_culture_type.${type}.characteristics`),
            ).map((characteristic, index) => (
              <li key={index}>{characteristic}</li>
            ))}
          </ul>
          {isArray(
            t(`results.understanding_culture_type.${type}.conclusion`),
          ).map((conclusion, index, array) => (
            <p key={index} className={index !== array.length - 1 ? 'mb-4' : ''}>
              {conclusion}
            </p>
          ))}
        </div>
      ))}
    </>
  )
}
const AdditionalTipsAndFinalThoughts = ({
  additionalTips,
  finalThoughtsContent,
  t,
}) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <div>
      <h3 className="my-3 font-bold">{t(`results.all_culture_types.p1`)}</h3>
      <ul className="ml-6 list-disc">
        {isArray(additionalTips).map((tip, index) => (
          <li
            key={index}
            className={index !== additionalTips.length - 1 ? 'mb-4' : ''}
          >
            {tip}
          </li>
        ))}
      </ul>
      <br />
      <h3 className="mb-3 font-bold">
        {t(`results.all_culture_types.final_thoughts.header`)}
      </h3>
      <div>
        {isArray(finalThoughtsContent).map((content, index) => (
          <p
            key={index}
            className={index !== finalThoughtsContent.length - 1 ? 'mb-4' : ''}
          >
            {content}
          </p>
        ))}
      </div>
    </div>
  )
}
