import {
  ArrowPathIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'
import { Button } from '../../../lib/ui/button'
import { useTranslation } from '../../../hooks/useTranslation'

export function Feedback({ title, feedback, current }) {
  const Content =
    feedback?.resume && current === 'resume'
      ? ResumeInProgress
      : feedback?.orientationCall && current === 'orientation'
        ? OrientationFeedback
        : feedback?.careerConsultation && current === 'career'
          ? CareerConsultationFeedback
          : NoFeedback

  return (
    <div className="mx-2 my-6">
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">{title}</h1>

        <Content feedback={feedback} />
      </div>
    </div>
  )
}

function NoFeedback() {
  const { t } = useTranslation('Feedback')

  return (
    <div className="text-center text-2xl">
      <p>{t('no_current_feedback')}</p>
    </div>
  )
}

function ResumeInProgress({ feedback }) {
  const coach = feedback?.resume_review_request?.coach
  const email = coach ? coach.email : 'support@yournextstep.com'
  const { t } = useTranslation('Feedback')

  return (
    <div className="flex flex-col gap-6">
      <div className="">
        <p>{t('thank_you_submitting_resume')}</p>
        <p>
          {t('if_you_have_questions_html', {
            email: email,
          })}{' '}
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">{t('resume_review_process')}</h2>

        <ul className="ml-6 flex list-disc flex-col gap-2">
          <li>{t('resume_review_process_description_p1')}</li>
          <li>{t('resume_review_process_description_p2')}</li>
          <li>{t('resume_review_process_description_p3')} </li>
        </ul>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">{t('your_uploaded_resume')}</h2>

        <div className="flex list-disc flex-col gap-2 rounded-xl border border-cod-gray-200 p-6 text-cod-gray-500">
          <div>
            {t('original_resume')}:{' '}
            <a
              href={feedback.resume.document_url}
              target="_blank"
              rel="noreferrer"
              className="text-intoo-green-coachcta hover:text-intoo-green-coachcta-hover"
            >
              {feedback.resume.document_name}{' '}
              <DocumentArrowDownIcon className="inline size-4" />
            </a>{' '}
            -{' '}
            <a
              // href="/resume/review/refresh"
              className="modal-remote text-intoo-green-coachcta hover:cursor-pointer hover:text-intoo-green-coachcta-hover"
              data-url="/resume/review/refresh"
              data-key="submit-your-resume-key"
            >
              {t('upload_a_new_resume')}
              <ArrowPathIcon className="inline size-4" />
            </a>
          </div>
          <div>
            {t('submitted')}
            {feedback.resume.submitted_date}
          </div>
          <div>
            {t('remarks')}{' '}
            <p className="pt-1 text-xs">{feedback.resume.remarks}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function OrientationFeedback({ feedback }) {
  const coach = feedback?.orientationCall?.coach_name
  const message = feedback?.orientationCall?.message
  const coach_message = feedback?.orientationCall?.coach_message

  const { t } = useTranslation('Feedback')

  return (
    <div className="flex flex-col gap-6">
      <div className="">
        <p>
          {t('has_completed_your_call_html', {
            coach: coach,
          })}
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">{t('orientation_call_summary')}</h2>

        <div className="flex list-disc flex-col gap-2 rounded-xl border border-cod-gray-200 p-6 text-cod-gray-500">
          <div>
            {t('message_html', {
              message: message,
            })}
          </div>
          <div>
            {t('coach_message_html', {
              coach_message: coach_message,
            })}
          </div>
        </div>
      </div>

      <div>
        <Button>
          <a
            href={feedback?.orientationCall?.document_url}
            className="text-white"
            data-method="get"
            data-remote="true"
          >
            {t('view_orientation_feedback')}
          </a>
        </Button>
      </div>
    </div>
  )
}

function CareerConsultationFeedback({ feedback }) {
  const coach = feedback?.careerConsultation?.coach_name
  const message = feedback?.careerConsultation?.message
  const coach_message = feedback?.careerConsultation?.coach_message

  const { t } = useTranslation('Feedback')

  return (
    <div className="flex flex-col gap-6">
      <div className="">
        <p>
          {t('has_completed_your_call_html', {
            coach: coach,
          })}
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">Career Consultation Call Summary</h2>

        <div className="flex list-disc flex-col gap-2 rounded-xl border border-cod-gray-200 p-6 text-cod-gray-500">
          <div>
            {t('message_html', {
              message: message,
            })}
          </div>
          <div>
            {t('coach_message_html', {
              coach_message: coach_message,
            })}
          </div>
        </div>
      </div>

      {feedback?.careerConsultation?.document_url && (
        <div>
          <Button>
            <a
              href={feedback?.careerConsultation?.document_url}
              className="text-white"
              data-method="get"
              data-remote="true"
            >
              {t('view_career_consultation_feedback')}
            </a>
          </Button>
        </div>
      )}
    </div>
  )
}
