import React, { createContext, useContext, useState } from 'react'
import { apiPatch } from '../../util/api'
import { useIsMobile, useIsDesktop } from '../../hooks/useMediaQuery'
import usePersistState from '../../hooks/usePersistState'

const ResourcesContext = createContext()

export const useResources = () => {
  return useContext(ResourcesContext)
}

const ResourcesProvider = ({
  serialized_article_list,
  serialized_audio_list,
  serialized_quiz_list,
  serialized_workshop_list,
  serialized_exercise_list,
  serialized_video_list,
  favorite_article_ids,
  favorite_resources,
  initial_tab,
  module_name,
  children,
}) => {
  const articles = serialized_article_list
  const audioFiles = serialized_audio_list
  const quizzes = serialized_quiz_list
  const workshops = serialized_workshop_list
  const exercises = serialized_exercise_list
  const videos = serialized_video_list
  const moduleName = module_name
  const [favorites, setFavorites] = useState(favorite_article_ids || [])

  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  const [favoritedResources, setFavoritedResources] = useState(
    favorite_resources || {},
  )

  const [activity, setActivity] = usePersistState(
    initial_tab || 'articles',
    'resourcesActivity',
  )

  const currentPath =
    window.location.pathname.slice(-1) === '/'
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname

  const toggleFavorite = (article) => {
    const newIsFavorite = !checkFavorite(article)
    if (newIsFavorite) {
      setFavorites([...favorites, article.id])
    } else {
      setFavorites(favorites.filter((id) => id !== article.id))
    }
    patchFavorite(article, newIsFavorite)
  }

  const checkFavorite = (article) => {
    return favorites.includes(article.id)
  }

  const toggleResourceFavorite = (resource) => {
    const newIsFavorite = !checkResourceFavorite(resource)

    setFavoritedResources((prevFavoritedResources) => {
      const oldFavorites = {
        ...prevFavoritedResources,
      }

      const oldSlugs = oldFavorites[resource.type] || []

      const newSlugs = newIsFavorite
        ? [...oldSlugs, resource.slug]
        : oldSlugs.filter((slug) => slug !== resource.slug)
      const newFavorites = {
        ...oldFavorites,
        [resource.type]: newSlugs,
      }
      return newFavorites
    })

    patchFavoriteResource(resource, newIsFavorite)
  }

  const checkResourceFavorite = (resource) => {
    const { type, slug } = resource

    if (favoritedResources[type]) {
      return favoritedResources[type].includes(slug)
    }

    return false
  }

  const getArticlePath = (article) => {
    return `${currentPath}/article/${article.slug}`
  }

  const patchFavorite = async (article, isFavorite) => {
    const articlePath = getArticlePath(article)
    const data = { is_favorite: isFavorite }
    try {
      const response = await apiPatch(articlePath, {
        candidate_article: data,
      })
      if (response.status !== 200) {
        console.error(
          'Updating CandidateArticle failed with response',
          response,
        )
      }
    } catch (error) {
      console.error('Error updating CandidateArticle', error)
    }
  }

  const patchFavoriteResource = async (resource, isFavorite) => {
    const resourcePath = `${currentPath}/update_favorites`
    const data = {
      resource_slug: resource.slug,
      resource_type: resource.type,
      is_favorite: isFavorite,
    }
    try {
      const response = await apiPatch(resourcePath, data)
      if (response.status !== 200) {
        console.error(
          'Updating FavoritedResource failed with response',
          response,
        )
      }
    } catch (error) {
      console.error('Error updating FavoritedResource', error)
    }
  }

  const openArticlesTab = () => {
    setActivity('articles')
  }
  const articlesActive = activity === 'articles'

  const openAudioTab = () => {
    setActivity('audio')
  }
  const audioActive = activity === 'audio'

  const openFavoritesTab = () => {
    setActivity('favorites')
  }
  const favoritesActive = activity === 'favorites'

  const openExercisesTab = () => {
    setActivity('exercises')
  }
  const exercisesActive = activity === 'exercises'

  const openQuizzesTab = () => {
    setActivity('quizzes')
  }
  const quizzesActive = activity === 'quizzes'

  const openWebinarsTab = () => {
    setActivity('webinars')
  }
  const webinarsActive = activity === 'webinars'

  const openWorkshopsTab = () => {
    setActivity('workshops')
  }
  const workshopsActive = activity === 'workshops'

  const isRecentlyAdded = (article) => {
    const today = new Date()
    const articleDate = new Date(article.created_at)
    const timeDifference = Math.abs(today - articleDate)
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return dayDifference < 30
  }

  const hasFavorites = () => {
    const hasArticleFavorites = favorites.length > 0
    const hasResourceFavorites = Object.values(favoritedResources).some(
      (resourceList) => resourceList.length > 0,
    )
    return hasArticleFavorites || hasResourceFavorites
  }

  const hasResources = (resourceType) => {
    switch (resourceType) {
      case 'articles':
        return articles.length > 0
      case 'audio':
        return audioFiles.length > 0
      case 'quizzes':
        return quizzes.length > 0
      case 'workshops':
        return workshops.length > 0
      case 'exercises':
        return exercises.length > 0
      case 'videos':
        return videos.length > 0
      case 'favorites':
        return favorites.length > 0
      default:
        return false
    }
  }

  const value = {
    articles,
    checkFavorite,
    toggleFavorite,
    openArticlesTab,
    openAudioTab,
    openFavoritesTab,
    openQuizzesTab,
    openWebinarsTab,
    openExercisesTab,
    articlesActive,
    audioActive,
    audioFiles,
    quizzes,
    favoritesActive,
    quizzesActive,
    webinarsActive,
    exercisesActive,
    getArticlePath,
    workshops,
    openWorkshopsTab,
    workshopsActive,
    exercises,
    videos,
    isRecentlyAdded,
    checkResourceFavorite,
    toggleResourceFavorite,
    hasFavorites,
    hasResources,
    moduleName,
    isMobile,
    isDesktop,
  }

  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  )
}

export default ResourcesProvider
