import * as React from 'react'

import { CoachCard } from './CoachCard'
import { CoachButton } from './CoachButton'
import { PhoneIcon } from '@heroicons/react/24/outline'
import { Calendar } from './Calendar'
import { ResumeReviewCard } from './cards/ResumeReviewCard'
import { CoachHeader } from '../CoachHeader'

export default function CandidateFlexBenefits({
  calendar_events,
  benefit_status,
}) {
  return (
    <div className="m-4 flex flex-col gap-10 font-roboto md:m-12">
      <div className="flex flex-col gap-6">
        <CoachHeader
          title="Your Outplacement Benefits"
          description="You have access to all of the following benefits:"
        />

        <div className="grid gap-10 xl:w-[840px] xl:grid-cols-2 2xl:w-[1280px] 2xl:grid-cols-3">
          <CoachCard
            icon={PhoneIcon}
            title="On-Demand Coach Chat"
            description="One-on-one coaching to help you navigate your job search."
          >
            <CoachButton
              className="open-chat-modal"
              data-toggle="modal"
              data-target="#coach_chat_list"
              data-action="Upper Start a Coach Chat"
            >
              Start a Coach Chat
            </CoachButton>

            <CoachButton variant="outline" asChild>
              <a href="/coach_services/feedback?tab=history">Learn More</a>
            </CoachButton>
          </CoachCard>

          <ResumeReviewCard status={benefit_status.resume_review} />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="font-roboto-slab text-2xl font-bold text-intoo-blue-medium">
          Upcoming Live Webinars
        </h2>
        <p>Learn from the experts. Register for a live webinar today.</p>
        <Calendar calendar_events={calendar_events} />
      </div>
    </div>
  )
}
