import React, { useState } from 'react'
import useRankTopValues, {
  RankTopValuesProvider,
} from './RankTopValuesProvider'
import { DndContext } from '@dnd-kit/core'
import { Draggable, Droppable } from '../../shared/DragAndDrop'
import { cn } from '../../../lib/utils'

const RankTopValues = ({ ...props }) => {
  return (
    <RankTopValuesProvider {...props}>
      <RankTopValuesInner />
    </RankTopValuesProvider>
  )
}

export default RankTopValues

const RankTopValuesInner = () => {
  const [dragging, setDragging] = useState(false)
  const { initialRankedValues, selectedValues, updateDocument } =
    useRankTopValues()
  const [rankedValues, setRankedValues] = useState(initialRankedValues)
  const items = selectedValues
  const droppables = [1, 2, 3, 4, 5, 6, 7, 8]
  const handleDragEnd = (e) => {
    setDragging(false)
    const { active, over } = e
    const newValues = { ...rankedValues }

    if (over && active) {
      if (Object.values(rankedValues).includes(over?.id)) {
        newValues[active.id] = over.id
        let repeatValuesExist = true
        while (repeatValuesExist) {
          repeatValuesExist = false
          const values = Object.keys(rankedValues)
          values.forEach((value) => {
            const countRepeatedRankings = Object.values(newValues).filter(
              (ranking) => ranking === rankedValues[value],
            ).length
            if (countRepeatedRankings > 1) {
              repeatValuesExist = true
              const allValues = Object.keys(newValues).filter(
                (key) => rankedValues[key] === rankedValues[value],
              )
              const lastVal = allValues[allValues.length - 1]
              if (rankedValues[value] + 1 > 8) {
                newValues[lastVal] = 1
              } else newValues[lastVal] = rankedValues[value] + 1
            }
          })
        }
        updateDocument(newValues)
        setRankedValues(newValues)
        return
      }
      newValues[active.id] = over.id
      updateDocument(newValues)
      setRankedValues(newValues)
      return
    }
    if (!over) {
      delete newValues[active?.id]
      updateDocument(newValues)
      setRankedValues(newValues)
      return
    }
  }

  return (
    <div>
      <DndContext
        onDragStart={() => setDragging(true)}
        onDragEnd={handleDragEnd}
      >
        <div className="grid grid-cols-2 gap-8">
          <div className="flex flex-col gap-4">
            {droppables.map((id) => {
              if (Object.values(rankedValues).includes(id)) {
                const key = Object.keys(rankedValues).find(
                  (key) => rankedValues[key] === id,
                )
                return (
                  <Droppable
                    className={cn(
                      'rounded-[15px] text-intoo-blue-medium',
                      dragging &&
                        'h-10 border border-dashed border-intoo-blue-medium',
                    )}
                    id={id}
                    key={`draggable-item-${id}`}
                  >
                    <DraggableItem className={'w-full'} value={key} />
                  </Droppable>
                )
              }
              return (
                <Droppable
                  className="flex h-10 justify-center rounded-[15px] border border-dashed border-intoo-blue-medium text-intoo-blue-medium"
                  key={`droppable-container-${id}`}
                  id={id}
                >
                  <div className="flex items-center justify-center font-roboto">
                    {id === 1 && 'Most Important '}
                    {id === droppables.length && 'Least Important '}+
                  </div>
                </Droppable>
              )
            })}
          </div>
          <div className="flex flex-col gap-4">
            {items.map((value) => {
              return (
                !Object.keys(rankedValues).includes(value) && (
                  <DraggableItem
                    key={`draggable-item-${value}`}
                    value={value}
                  />
                )
              )
            })}
          </div>
        </div>
      </DndContext>
    </div>
  )
}

const DraggableItem = ({ className, value }) => {
  const { getValueName } = useRankTopValues()
  const valueName = getValueName(value)
  return (
    <Draggable
      className={cn(
        'rounded-[15px] border-2 border-intoo-blue-medium bg-white font-roboto capitalize text-intoo-blue-medium hover:bg-intoo-blue-medium hover:text-white',
        className,
      )}
      id={value}
    >
      {valueName}
    </Draggable>
  )
}
