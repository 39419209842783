import React from 'react'
import useShortCourses from './ShortCourseProvider'
import { ClockIcon } from 'lucide-react'
import { BrainIcon, PuzzleIcon, AylaIcon } from './icons'
import useTranslation from '../shared/TranslationProvider'

const pluralize = (count, singular, plural) => {
  return count > 1 ? plural : singular
}

const CourseOverview = () => {
  const { slug } = useShortCourses()
  const { t } = useTranslation(`${slug}.overview`)
  const sections = t('sections')
  return (
    <div className="top-60 flex w-full justify-center md:absolute">
      <div className="flex flex-col border-[#e5e5e5] bg-white p-4 md:grid md:grid-cols-4 md:divide-x md:divide-[#e5e5e5] md:rounded-xl md:border">
        {sections.map((section, idx) => (
          <Section
            key={`section-${idx}`}
            className="flex-1 text-center"
            section={section}
          />
        ))}
      </div>
    </div>
  )
}

export default CourseOverview

const Section = ({ section }) => {
  const { title, amount, singular, plural } = section
  const noun = pluralize(amount, singular, plural)
  const icons = {
    time: <ClockIcon />,
    exercises: <BrainIcon />,
    quizzes: <PuzzleIcon />,
    ayla: <AylaIcon />,
  }
  return (
    <div className="flex items-center justify-center text-intoo-blue-medium">
      <div className="mr-2">{icons[title]}</div>
      <div>
        {amount} {noun}
      </div>
    </div>
  )
}
