import React from 'react'
import { useSkills } from './SkillsProvider'

const NavHeader = () => {
  return (
    <div className="relative mb-6 w-full overflow-x-scroll border-b border-solid border-[#979797] sm:static sm:overflow-auto">
      <div className="flex flex-row justify-between gap-5 min-[530px]:justify-start min-[530px]:gap-10">
        <GraphViewHeaderButton />
        <CardViewHeaderButton />
        <DesiredSkillsHeaderButton />
      </div>
    </div>
  )
}
export default NavHeader

const HeaderButton = ({ icon, title, onClick, active = false }) => {
  const activeClass = active ? 'w-full rounded-t-sm h-1 bg-primary' : 'h-1'
  const titleClass = active ? 'font-bold' : ''

  return (
    <button onClick={onClick} className="flex min-w-16 flex-col items-center">
      {icon}
      <div
        className={`my-2 whitespace-nowrap text-center text-sm text-black ${titleClass}`}
      >
        {title}
      </div>
      <div className={activeClass}></div>
    </button>
  )
}

const GraphViewHeaderButton = () => {
  const { openGraphViewTab, graphViewActive } = useSkills()

  return (
    <HeaderButton
      onClick={openGraphViewTab}
      title="Skills Matrix"
      active={graphViewActive}
    />
  )
}

const CardViewHeaderButton = () => {
  const { openCardViewTab, cardViewActive } = useSkills()

  return (
    <HeaderButton
      onClick={openCardViewTab}
      title="Rank My Skills"
      active={cardViewActive}
    />
  )
}

const DesiredSkillsHeaderButton = () => {
  const { openDesiredSkillsTab, desiredSkillsActive, desiredSkillsPresent } =
    useSkills()

  if (!desiredSkillsPresent) return null

  return (
    <HeaderButton
      onClick={openDesiredSkillsTab}
      title="Desired Skills"
      active={desiredSkillsActive}
    />
  )
}
