import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { apiPatch } from '../../../util/api'

const useSavedRecruitersForm = (savedRecruiter) => {
  const id = savedRecruiter.id
  const [submitted, setSubmitted] = useState(false)
  const schema = z.object({
    firm_name: z.string().optional(),
    email: z.string().email().optional(),
    phone: z.string().optional(),
    website: z.string().optional(),
    recruiter_name: z.string().optional(),
    follow_up: z.union([z.date(), z.null(), z.string()]).optional(),
    notes: z.string().optional(),
    resume: z.union([z.instanceof(File), z.null(), z.string()]).optional(),
  })

  const defaultValues = {
    firm_name: savedRecruiter.firm_name,
    email: savedRecruiter.email,
    phone: savedRecruiter.phone,
    website: savedRecruiter.website,
    recruiter_name: savedRecruiter.recruiter_name,
    follow_up: savedRecruiter.follow_up_date,
    notes: savedRecruiter.notes || '',
    resume: savedRecruiter.resume,
  }

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values) => {
    setSubmitted(true)

    const url = `/job-search/recruiters/my_recruiters/${id}`

    const data = {
      saved_recruiter: {
        firm_name: values.firm_name,
        email: values.email,
        phone: values.phone,
        website: values.website,
        recruiter_name: values.recruiter_name,
        follow_up_date: values.follow_up,
        notes: values.notes,
        resume: values.resume,
      },
    }
    const response = await apiPatch(url, data, true)

    if (response.status === 200) {
      console.log('Success')
      window.location.reload()
    } else {
      console.log(response)
      throw new Error('Failed to save recruiter')
    }
  }
  return { form, onSubmit, submitted }
}

export default useSavedRecruitersForm
