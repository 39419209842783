import React from 'react'
import { cn } from '../../lib/utils'
import parse from 'html-react-parser'
const Tile = ({ children }) => {
  return (
    <div className="relative h-full max-w-[300px] cursor-pointer rounded-[10px] bg-white">
      {children}
    </div>
  )
}

const CourseAccessType = ({ className, children }) => {
  return (
    <div
      className={cn(
        'absolute left-0 top-0 rounded-br-[10px] rounded-tl-[10px] pb-[10px] pl-[10px] pr-[7px] pt-[7px] font-bold text-white',
        className,
      )}
    >
      {children}
    </div>
  )
}

const CourseImage = ({ course }) => {
  return (
    <div className="h-[225px] overflow-hidden rounded-tr-[10px]">
      <img className="rounded-t-[10px]" src={course.image} alt={course.name} />
    </div>
  )
}

const CourseContent = ({ children }) => {
  return <div className="p-[20px]">{children}</div>
}

const CourseName = ({ children }) => {
  return <div className="text-lg font-black">{children}</div>
}

const CourseDescription = ({ children }) => {
  return <div className="px-0 pb-[2.5rem] pt-[10px]">{children}</div>
}

const BottomRow = ({ children }) => {
  return <div className="absolute bottom-[20px] left-0 w-full">{children}</div>
}

const LearnMore = ({ course, children }) => {
  return (
    <div className="relative z-[2] float-left pl-[20px]">
      <a className="btn rounded-[20px] bg-primary text-white" href={course.url}>
        {children}
      </a>
    </div>
  )
}

const Logo = ({ course }) => {
  return (
    <div className="relative float-right pr-[20px]">
      <img className="mt-[10px] w-full" src={course.logo_url} alt="" />
    </div>
  )
}

const CourseTile = ({ course, t }) => {
  const paid = course.access_type !== 'free'
  return (
    <Tile>
      <a
        target="_blank"
        href={course.url}
        rel="noopener noreferrer"
        className="text-[#005b94]"
      >
        <span className="absolute left-0 top-0 z-[1] h-full w-full"></span>
      </a>

      <CourseAccessType className={paid ? 'bg-[#123f1c]' : 'bg-primary'}>
        {paid ? t('courses.pay_to_access') : t('courses.free_access')}
      </CourseAccessType>
      <CourseImage course={course} />
      <CourseContent>
        <CourseName>{course.name}</CourseName>
        <CourseDescription>
          {course.description
            ? parse(course.description.substring(0, 95) + '...')
            : ''}
        </CourseDescription>
        <BottomRow>
          <div className="row">
            <div className="col-lg-6">
              <LearnMore course={course}>{t('courses.learn_more')}</LearnMore>
            </div>
            <div className="col-lg-6">
              <Logo course={course} />
            </div>
          </div>
        </BottomRow>
      </CourseContent>
    </Tile>
  )
}
export default CourseTile
