import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '../../../lib/ui/form'
import useSavedRecruitersForm from './useSavedRecruiterForm'
import { Input } from '../../../lib/ui/input'
import { Textarea } from '../../../lib/ui/textarea'
import { Button } from '../../../lib/ui/button'
import { DatePickerField } from '../../shared/DatePickerField'
import { cn } from '../../../lib/utils'
import useSavedRecruiters from './SavedRecruitersProvider'
const ExpandedCell = ({ row }) => {
  const { deleteRecruiter } = useSavedRecruiters()
  const { form, onSubmit, submitted } = useSavedRecruitersForm(row.original)
  const acceptList = ['.pdf', '.rtf', '.doc', '.docx', '.txt']
  const rowClasses = cn('flex flex-col gap-4 md:flex-row md:gap-12')
  const inputClasses = cn('md:flex-grow')

  const { t } = useTranslation()

  const handleDeleteItem = async (e) => {
    e.preventDefault()
    window.confirm(t('FormActions.delete_confirm')) && deleteRecruiter(row)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="p-4">
          <div className="flex flex-col gap-4 ">
            <div className={rowClasses}>
              <InputField
                control={form.control}
                label={t('TableHeader.firm_name')}
                name="firm_name"
              />
              <InputField
                control={form.control}
                label={t('TableHeader.email')}
                name="email"
                className={inputClasses}
              />
              <InputField
                control={form.control}
                label={t('TableHeader.phone')}
                name="phone"
              />
              <InputField
                control={form.control}
                label={t('TableHeader.website')}
                name="website"
                className={inputClasses}
              />
            </div>
            <div className={rowClasses}>
              <InputField
                control={form.control}
                label={t('TableHeader.name')}
                name="recruiter_name"
                className={inputClasses}
              />
              <DatePickerField
                control={form.control}
                label={t('TableHeader.followup_on')}
                name="follow_up"
                notRounded={true}
                innerClassName={'bg-input-background'}
              />
              <div className="flex flex-col">
                <FileField
                  control={form.control}
                  label={t('TableHeader.resume')}
                  name="resume"
                  accept={acceptList.join(',')}
                  className={'w-80'}
                />
                <div className="hint">
                  {t('FormActions.upload_resume_hint')}
                </div>
              </div>
            </div>
            <TextField control={form.control} label="Notes" name={'notes'} />
            <div className="flex gap-4 self-end">
              <Button
                onClick={handleDeleteItem}
                className="bg-transparent text-red-500 hover:bg-transparent hover:text-red-400"
                variant="destructive"
              >
                {t('FormActions.delete')}
              </Button>
              <Button disabled={submitted} type="submit">
                {t('FormActions.save')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  )
}

export default ExpandedCell

export const InputField = ({ control, label, name, className }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
const TextField = ({ control, label, name }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea className={'resize-none'} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const FileField = ({ control, label, name, accept, className }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <FormItem className="">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              {...fieldProps}
              type="file"
              accept={accept}
              name={name}
              onChange={(e) => onChange(e.target.files && e.target.files[0])}
              className={cn(
                'mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-base shadow-sm focus:border-accent focus:ring-accent sm:text-sm',
                className,
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
