import * as React from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import { api } from '../../../util/api'

const getRecruiterSearch =
  ({ paramString }) =>
  async ({ pageParam }) => {
    const response = await api.get(`?page=${pageParam}&${paramString}`)
    return response.data
  }

export function useRecruiterSearchQuery({ paramString, initialData }) {
  const [lastInitialData, setLastInitialData] = React.useState(initialData)
  const [pages, setPages] = React.useState([])
  const [recruiterResults, setRecruiterResults] = React.useState([])
  const [pagesToShow, setPagesToShow] = React.useState(1)
  const [prefetchedPages, setPrefetchedPages] = React.useState(false)

  const query = useInfiniteQuery({
    queryKey: ['recruiter-search', paramString],
    queryFn: getRecruiterSearch({ paramString }),
    initialData: lastInitialData,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages) => lastPage.next_page,
    refetchOnWindowFocus: false,
  })

  const {
    dataUpdatedAt,
    error,
    errorUpdatedAt,
    failureCount,
    isError,
    isFetched,
    isFetchedAfterMount,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isPending,
    isIdle,
    isLoading,
    isPlaceholderData,
    isPreviousData,
    isStale,
    isSuccess,
    status,
    updatedAt,
    fetchNextPage,
  } = query

  const queryDataPages = query.data?.pages
  const queryData = query.data
  const queryDataPagesLength = queryDataPages?.length || 0
  const pagesLength = pages?.length || 0
  const pagesLastTotalResults = pages[pages.length - 1]?.total_results || 0
  const pagesLastNextPage = pages[pages.length - 1]?.next_page || 0
  const pagesLastTotalPages = pages[pages.length - 1]?.total_pages || 0
  const showViewMoreButton = pagesToShow < pagesLastTotalPages
  const recruiterResultsLength = recruiterResults?.length || 0
  const amountShown = recruiterResults?.length || 0
  const amountTotal = pagesLastTotalResults || 0

  const queryStatusMessage = JSON.stringify(
    {
      pagesToShow,
      queryDataPagesLength,
      pagesLength,
      hasNextPage,
      recruiterResultsLength,
      amountShown,
      amountTotal,
      pagesLastTotalResults,
      pagesLastNextPage,
      pagesLastTotalPages,
      showViewMoreButton,
      isFetched,
      isFetchedAfterMount,
      isFetching,
      isFetchingNextPage,
      isPending,
      isIdle,
      isLoading,
      isPreviousData,
      isStale,
      isSuccess,
      status,
      updatedAt,
      dataUpdatedAt,
      isPlaceholderData,
      error,
      errorUpdatedAt,
      failureCount,
      isError,
    },
    null,
    2,
  )
  const debug = false
  if (debug) console.log('query status: ', queryStatusMessage)

  // Show a few less pages than we have
  React.useEffect(() => {
    if (queryDataPagesLength === 1) {
      setPages(queryDataPages)
    } else if (queryDataPagesLength > 1) {
      setPages(queryDataPages?.slice(0, pagesToShow))
    }
  }, [queryDataPages, pagesToShow, queryDataPagesLength])

  // Set recruiters when pages are updated
  React.useEffect(() => {
    const recruiters = pages.map((page) => page.results).flat()
    setRecruiterResults(recruiters)
  }, [pages])

  // Prefetch pages
  React.useEffect(() => {
    if (pagesLength === 0) return
    if (!hasNextPage) return
    if (isFetching) return
    if (prefetchedPages) return

    const fetchPagesAhead = async () => {
      await fetchNextPage()
    }
    setPrefetchedPages(true)
    fetchPagesAhead()
  }, [fetchNextPage, hasNextPage, isFetching, pagesLength, prefetchedPages])

  React.useEffect(() => {
    setLastInitialData()
  }, [queryData])

  return {
    query,
    queryStatusMessage,
    pagesToShow,
    setPagesToShow,
    setPrefetchedPages,
    recruiterResults,
    amountShown,
    amountTotal,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isPending,
    showViewMoreButton,
  }
}
