import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export const useRecruiterSearchForm = ({
  urlSearchParams,
  lockParamString,
  noResultQuery,
  setPagesToShow,
  setPrefetchedPages,
}) => {
  const FormSchema = z.object({
    what: z.string().optional(),
    where: z.string().optional(),
    filter_firm_type: z.array(z.string()).optional(),
    filter_salary: z.array(z.string()).optional(),
    filter_industry: z.string().optional(),
  })

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      what: urlSearchParams?.get('what') || '',
      where: urlSearchParams?.get('where') || '',
      filter_firm_type: urlSearchParams?.getAll('filter_firm_type[]') || [],
      filter_salary: urlSearchParams?.getAll('filter_salary[]') || [],
      filter_industry: urlSearchParams?.get('filter_industry') || '',
    },
  })

  const onSubmit = async (data) => {
    lockParamString()
    setPagesToShow(1)
    setPrefetchedPages(false)
  }

  return {
    form,
    onSubmit,
    noResultQuery,
  }
}
