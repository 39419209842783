import React from 'react'
import DataProvider, { useData } from './DataProvider'
import StepperDisplay from './StepperDisplay'
import { TranslationProvider } from '../shared/TranslationProvider'
import { ThemeProvider } from '../shared/ThemeProvider'
import translations from './locales.json'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3/Step3'
import Step4 from './Step4'
import Step5 from './Step5'

const OnboardingStepsInternal = () => {
  const { step } = useData()
  return (
    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <div className="px-4 md:p-0">
        <StepperDisplay />
        {step == 1 && <Step1 />}
        {step == 2 && <Step2 />}
        {step == 3 && <Step3 />}
        {step == 4 && <Step4 />}
        {step == 5 && <Step5 />}
      </div>
    </div>
  )
}

const OnboardingSteps = ({
  locale = null,
  activeStep = 1,
  statoCustomContent = null,
}) => {
  const scope = 'javascript.components.OnboardingSteps'
  return (
    <TranslationProvider
      translations={translations}
      locale={locale || 'en'}
      scope={scope}
    >
      <DataProvider
        fetchOnLoad
        activeStep={activeStep}
        statoCustomContent={statoCustomContent}
      >
        <ThemeProvider>
          <OnboardingStepsInternal />
        </ThemeProvider>
      </DataProvider>
    </TranslationProvider>
  )
}

export default OnboardingSteps
