import React, { useState, useEffect } from 'react'
import TileIntooIcon from './TileIntooIcon'
// import { TrackItemMenu } from './TrackItemMenu'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button } from '../../../lib/ui/button'
import {
  VideoIcon,
  ArticleIcon,
  ExerciseIcon,
  AssessmentIcon,
  QuizIcon,
  AylaIcon,
  WebinarIcon,
  AudioIcon,
  WorkshopIcon,
  ChecklistIcon,
  TimeIcon,
  SearchToolIcon,
  BuilderIcon,
  CoachServiceIcon,
  SampleDocIcon,
} from './icons'
import { apiPatch } from '../../../util/api'
import { useActionPlan } from '../ActionPlanProvider'
import { cn } from '../../../lib/utils'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton'

export const itemTypeMap = {
  article: { text: 'Read Article', icon: <ArticleIcon /> },
  video: { text: 'Watch Video', icon: <VideoIcon /> },
  quiz: { text: 'Take Quiz', icon: <QuizIcon /> },
  exercise: { text: 'Take Exercise', icon: <ExerciseIcon /> },
  ayla: { text: 'Go to Ayla', icon: <AylaIcon /> },
  webinar: { text: 'Watch Webinar', icon: <WebinarIcon /> },
  assessment: { text: 'Take Assessment', icon: <AssessmentIcon /> },
  audio: { text: 'Listen to Audio', icon: <AudioIcon /> },
  workshop: { text: 'Take Workshop', icon: <WorkshopIcon /> },
  coach_service: { text: 'View Coach Service', icon: <CoachServiceIcon /> },
  builder: { text: 'Go to Builder', icon: <BuilderIcon /> },
  search_tool: { text: 'Use Tool', icon: <SearchToolIcon /> },
  checklist: { text: 'View Checklist', icon: <ChecklistIcon /> },
  sample: { text: 'View Sample Doc', icon: <SampleDocIcon /> },
}

const ExpandedCurrentTile = ({ tileInfo, expanded, setExpanded }) => {
  // const [selected, setSelected] = useState(0)
  const sectionName = Object.keys(tileInfo)[0]
  const isDesktop = useIsDesktop()
  const alignItems = isDesktop ? '' : 'items-center'
  const { t } = useTranslation()
  const [completedTasks, setCompletedTasks] = useState(
    tileInfo[sectionName]['subsections'].filter(
      (task) => task.completed || task.skipped,
    ).length,
  )
  const [completing, setCompleting] = useState(false)

  useEffect(() => {
    setCompletedTasks(
      tileInfo[Object.keys(tileInfo)[0]]['subsections'].filter(
        (task) => task.completed || task.skipped,
      ).length,
    )
  }, [tileInfo])

  function calculateCompletedTasks() {
    let totalTasks = tileInfo[sectionName]['subsections'].length
    return `${t('completed_tasks', {
      completed: completedTasks,
      total: totalTasks,
    })}`
  }

  const subsections = tileInfo[sectionName]['subsections'].sort((a, b) => {
    if (a.skipped && !b.skipped) {
      return 1
    } else if (!a.skipped && b.skipped) {
      return -1
    } else {
      return 0
    }
  })

  return (
    expanded && (
      <div
        className="section-tile expanded-tile mb-[24px] rounded-[16px]"
        style={{
          opacity: completing ? '0%' : '100%',
          transition: 'opacity 2s ease-in-out',
        }}
      >
        <div
          className={`flex flex-col ${alignItems} justify-between rounded-[16px] border-[3px] border-solid border-primary bg-white md:flex-row`}
        >
          <div className="w-full rounded-[16px] bg-white md:w-9/12">
            <div className={`flex justify-between rounded-tl-[16px] p-8`}>
              <div className="marker-container">
                <h2
                  className="mt-[16px] font-roboto-slab text-2xl font-bold text-primary"
                  style={{
                    marginTop: '0px',
                    display: 'inline',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {t(`${sectionName.split('_')[0]}_tasks`, {
                    number: t(sectionName.split('_')[1]),
                  })}
                  {isDesktop
                    ? ` (${calculateCompletedTasks()})`
                    : `\n(${calculateCompletedTasks()})`}
                </h2>
                <div className="pt-[16px]">
                  {t(`sections.descriptions.${tileInfo[sectionName]['focus']}`)}
                </div>
              </div>
            </div>
          </div>
          <div className="gradient-action-plan-expanded md:relative md:flex md:w-4/12 md:items-center md:justify-center md:rounded-[12px]">
            <TileIntooIcon className="absolute left-[10px] top-[2.5rem]" />
            <Button
              variant={isDesktop ? 'outline' : 'default'}
              onClick={() => setExpanded(null)}
              title={
                'View ' +
                t(`${sectionName.split('_')[0]}_tasks`, {
                  number: t(sectionName.split('_')[1]),
                })
              }
              className="mb-[32px] sm:mb-0 md:ml-[32%]"
            >
              {t('view')}
              <i className="el-icon-chevron-up ml-5"></i>
            </Button>
          </div>
        </div>
        <div className="flex flex-col flex-wrap gap-[24px] rounded-[16px] p-6">
          {subsections.map((tile) => {
            return (
              <TrackItem
                key={`expanded-${tile.slug}`}
                tile={tile}
                t={t}
                setCompletedTasks={setCompletedTasks}
                setCompleting={setCompleting}
              />
            )
          })}
        </div>
      </div>
    )
  )
}
export default ExpandedCurrentTile

const TrackItem = ({ tile, t, setCompletedTasks, setCompleting }) => {
  const [isSkipped, setIsSkipped] = useState(tile.skipped)
  const [skipping, setSkipping] = useState(false)
  const [isCompleted, setIsCompleted] = useState(tile.completed)
  // const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { trackerSectionInfo, setTrackerSectionInfo } = useActionPlan()

  const skipItemAnimation = (tile) => {
    if (!isSkipped) {
      setSkipping(true)
      setTimeout(() => {
        setIsSkipped(!isSkipped)
        tile.skipped = !isSkipped
        updateTrackerSections()
        setSkipping(false)
      }, 2550)
    } else {
      setIsSkipped(!isSkipped)
      tile.skipped = !isSkipped
      updateTrackerSections()
    }
  }

  const skipItem = async () => {
    const res = await apiPatch('/trackers/skip_item', {
      slug: tile.slug,
    })
    if (res.status === 200) {
      skipItemAnimation(tile)

      return res
    } else {
      throw new Error('res not ok')
    }
  }
  const completeSectionAnimation = (section) => {
    setCompleting(true)
    setTimeout(() => {
      let newTrackerSectionInfo = {
        incomplete: trackerSectionInfo['incomplete'].slice(1),
        complete: [...trackerSectionInfo['complete'], section],
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setTrackerSectionInfo(newTrackerSectionInfo)
      setCompleting(false)
    }, 2550)
  }

  const markItemComplete = async () => {
    const res = await apiPatch('/trackers/mark_item_complete', {
      achievement: tile.achievement,
    })
    if (res.status === 200) {
      setIsCompleted(true)
      tile.completed = true

      updateTrackerSections()

      return res
    } else {
      throw new Error('res not ok')
    }
  }

  const updateTrackerSections = () => {
    let section = trackerSectionInfo['incomplete'][0]
    let keys = Object.keys(section)
    let values = keys.map(function (v) {
      return section[v]
    })

    const subsections = values[0]['subsections']

    const completedSubsectionsCount = subsections.filter(
      (subsection) => subsection.completed || subsection.skipped,
    ).length

    setCompletedTasks(completedSubsectionsCount)

    // Check if all subsections are completed
    const allSubsectionsCompleted =
      completedSubsectionsCount === subsections.length

    let newTrackerSectionInfo

    if (allSubsectionsCompleted) {
      // Move section from 'incomplete' to 'complete'
      completeSectionAnimation(section)
    } else {
      newTrackerSectionInfo = {
        incomplete: trackerSectionInfo['incomplete'],
        complete: trackerSectionInfo['complete'],
      }

      setTrackerSectionInfo(newTrackerSectionInfo)
    }
  }

  const icon = itemTypeMap[tile.type].icon

  return (
    <>
      {tile.completed || tile.skipped ? (
        <CompletedTile
          tile={tile}
          icon={icon}
          t={t}
          isSkipped={isSkipped}
          skipItem={skipItem}
        />
      ) : (
        <IncompleteTile
          tile={tile}
          icon={icon}
          t={t}
          setIsSkipped={setIsSkipped}
          setIsCompleted={setIsCompleted}
          skipItem={skipItem}
          markItemComplete={markItemComplete}
          skipping={skipping}
        />
      )}
    </>
  )
}

const CompletedTile = ({ tile, icon, t, isSkipped, skipItem }) => {
  const background = isSkipped ? '#373737' : '#FFFFFF'
  const [skippedText, setSkippedText] = useState('Task Skipped')
  const { completionAnimationUrl } = useActionPlan()
  const isCoachService = tile['coach_service']

  return (
    <div
      className={cn(
        'track-item relative flex flex-col rounded-[16px] bg-white p-[16px] md:grid md:grid-cols-12',
        isSkipped ? 'skipped-track-item' : '',
      )}
    >
      <div
        className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-[16px] opacity-90"
        style={{
          background: background,
          display: isSkipped ? 'block' : 'none',
        }}
        onMouseOver={() => setSkippedText('Unskip Task')}
        onMouseOut={() => setSkippedText('Task Skipped')}
        onClick={() => skipItem()}
      >
        <div className="flex h-full items-center justify-center">
          <div className="font-roboto-slab text-2xl font-bold text-white">
            {skippedText}
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex h-full flex-col items-center justify-center">
          <img
            className="w-20"
            src={completionAnimationUrl}
            alt="Article complete"
          />
        </div>
      </div>
      <div className="col-span-6 flex grid grid-cols-12 items-center md:flex-row">
        <h3 className="col-span-8 text-xl font-medium">{tile.name}</h3>

        <div className="col-span-4 flex gap-4 md:flex-row">
          <div className="flex flex-col items-center justify-center gap-2 border-r border-cod-gray-200 pr-6">
            <TimeIcon variant={isCoachService ? 'coach' : 'dark'} />
            {tile.length}
          </div>
          <div className="flex flex-col items-center justify-center gap-2 pl-6">
            {React.cloneElement(icon, {
              variant: isCoachService ? 'coach' : 'dark',
              height: '24',
            })}
            <div className="capitalize">{tile.type}</div>
          </div>
        </div>
      </div>
      <div className="col-span-5 flex h-full flex-col justify-center">
        <div className="items-center md:grid md:grid-cols-12">
          <div className="col-span-5 col-start-6 text-center">
            <a href={tile.url}>
              <Button
                title={t('view') + ' ' + tile.name}
                href={tile.url}
                variant="outline"
              >
                {itemTypeMap[tile.type].text}
                <span className="ml-[8px]">
                  {React.cloneElement(icon, {
                    variant: isCoachService ? 'coach' : 'dark',
                    height: '24',
                  })}
                </span>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const IncompleteTile = ({
  tile,
  icon,
  skipItem,
  markItemComplete,
  skipping,
  t,
}) => {
  const isCoachService = tile['coach_service']

  return (
    <div
      className={cn(
        'track-item relative flex flex-col rounded-[16px] bg-white p-[16px] md:grid md:grid-cols-12',
        skipping ? 'skipped-track-item' : '',
      )}
    >
      <div
        className={cn(
          'absolute left-0 top-0 w-full cursor-pointer rounded-[16px]',
        )}
        style={{
          background: '#373737',
          height: skipping ? '100%' : '0px',
          opacity: skipping ? '90%' : '0%',
          transition: 'opacity 2s ease-in-out',
        }}
      ></div>
      <div className="col-span-1">
        <div className="flex h-full flex-col items-center md:pt-6">
          {React.cloneElement(icon, {
            variant: isCoachService ? 'coach' : 'primary',
            height: '40',
          })}
        </div>
      </div>
      <div className="col-span-6 flex flex-col gap-4">
        <h3 className="text-xl font-medium">{tile.name}</h3>
        {tile.description}
        <div className="flex gap-4 md:flex-row">
          <div className="flex flex-col items-center justify-center gap-2 border-r border-cod-gray-200 pr-6">
            <TimeIcon variant={isCoachService ? 'coach' : 'dark'} />
            {tile.length}
          </div>
          <div className="flex flex-col items-center justify-center gap-2 pl-6">
            {React.cloneElement(icon, {
              variant: isCoachService ? 'coach' : 'dark',
              height: '24',
            })}
            <div className="capitalize">{tile.type}</div>
          </div>
        </div>
      </div>
      <div className="col-span-5 flex h-full flex-col justify-center">
        <div className="items-center md:grid md:grid-cols-12">
          <div className="col-span-5 text-center">
            <a href={tile.url}>
              {isCoachService ? (
                <CoachButton
                  title={t('view') + ' ' + tile.name}
                  href={tile.url}
                  className="text-[14px]"
                >
                  {itemTypeMap[tile.type].text}
                  <span className="ml-[8px]">
                    {React.cloneElement(icon, {
                      variant: 'light',
                      height: '24',
                    })}
                  </span>
                </CoachButton>
              ) : (
                <Button title={t('view') + ' ' + tile.name} href={tile.url}>
                  {itemTypeMap[tile.type].text}
                  <span className="ml-[8px]">
                    {React.cloneElement(icon, {
                      variant: 'light',
                      height: '24',
                    })}
                  </span>
                </Button>
              )}
            </a>
          </div>
          <div className="col-span-5 text-center">
            <Button variant="outline" onClick={markItemComplete}>
              Mark as Completed
            </Button>
          </div>
          <div className="col-span-2 text-center">
            <Button
              onClick={skipItem}
              variant="link"
              className="cursor-pointer p-0 text-base !underline"
            >
              Skip
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
