import React, { createContext, useContext } from 'react'
import { apiPatch } from '../../../util/api'

const RankTopValuesContext = createContext()

const useRankTopValues = () => {
  return useContext(RankTopValuesContext)
}

export const RankTopValuesProvider = ({
  selectedValues,
  valueList,
  rankedValues,
  formOptions,
  children,
}) => {
  const { url } = formOptions

  const updateDocument = async (values) => {
    const dataValues = Object.values(values).length ? values : {}
    const data = { document_part: { rankings: dataValues } }
    const res = await apiPatch(url, data)
    if (!res.status === 200) {
      throw new Error('Failed to save response')
    }
  }

  const unrankedValues = selectedValues.filter((value) => {
    return !Object.keys(rankedValues || {}).includes(value)
  })

  const getValueName = (value) => {
    return valueList.find((val) => val.value === value).name
  }

  const initialRankedValues =
    unrankedValues.length > 0 ? {} : rankedValues || {}

  const value = {
    selectedValues,
    initialRankedValues,
    updateDocument,
    getValueName,
  }

  return (
    <RankTopValuesContext.Provider value={value}>
      {children}
    </RankTopValuesContext.Provider>
  )
}

export default useRankTopValues
