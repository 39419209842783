import * as React from 'react'
import { useRecruiterSearch } from './RecruiterSearchProvider'
import { Form } from '../../../lib/ui/form'
import SearchInput from '../JobSearchPage/SearchInput'
import { useTranslation } from '../../../hooks/useTranslation'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import MagnifyingGlassIcon from '../JobSearchPage/MagnifyingGlassIcon'
import MapPinIcon from '../JobSearchPage/MapPinIcon'
import SearchDropdown from './SearchDropdown'
import FilterDropdown from './FilterDropdown'
import FilterCheckboxGroup from './FilterCheckboxGroup'
import { PrimaryButton } from './buttons'

export default function ToolBar() {
  const { form, onSubmit, urlSearchParams } = useRecruiterSearch()
  const { t } = useTranslation('ToolBar')
  const isDesktop = useIsDesktop()
  const isMobile = !isDesktop

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex max-w-[1375px] flex-col">
          <h1 className="!mb-4 font-gothamRegular text-[#333]">
            {t('recruiter_search')}
          </h1>
          <p className="mb-4">{t('description')}</p>

          <div className="mb-6 flex basis-1/2 flex-row justify-between gap-3 align-text-bottom md:basis-1/3 md:gap-4">
            <SearchInputKeyword />

            <SearchInputWhere />

            {isDesktop && <FilterDropdownIndustry />}

            <DesktopSearchButton />
          </div>

          {isMobile && (
            <div
              className="flex flex-row flex-wrap gap-2"
              style={{ width: '48.5%' }}
            >
              <FilterDropdownIndustry />
            </div>
          )}

          <div className="flex flex-row flex-wrap gap-2 md:gap-4">
            <FilterDropdown
              title={t('type_of_firm')}
              hasChecked={urlSearchParams.has('filter_firm_type[]')}
            >
              <FirmTypeCheckboxes />
            </FilterDropdown>
            <FilterDropdown
              title={t('target_level_salary')}
              hasChecked={urlSearchParams.has('filter_salary[]')}
            >
              <SalaryCheckboxes />
            </FilterDropdown>
          </div>

          <MobileSearchButton />
        </div>
      </form>
    </Form>
  )
}

function SearchInputKeyword() {
  const { t } = useTranslation('SearchInputKeyword')
  const { form } = useRecruiterSearch()

  return (
    <SearchInput
      control={form.control}
      name="what"
      label={t('label')}
      placeholder={t('placeholder')}
      icon={MagnifyingGlassIcon}
      data-source="roles"
    />
  )
}

function SearchInputWhere() {
  const { t } = useTranslation('SearchInputWhere')
  const { form } = useRecruiterSearch()

  return (
    <SearchInput
      control={form.control}
      name="where"
      label={t('label')}
      placeholder={t('placeholder')}
      icon={MapPinIcon}
      data-source="locations"
    />
  )
}

function FilterDropdownIndustry() {
  const { t } = useTranslation('FilterDropdownIndustry')
  const { form, industries } = useRecruiterSearch()

  return (
    <SearchDropdown
      control={form.control}
      name="filter_industry"
      label={t('label')}
      placeholder={t('placeholder')}
      options={industries}
    ></SearchDropdown>
  )
}

function FirmTypeCheckboxes({ showLabel }) {
  const { t } = useTranslation('FirmTypeCheckboxes')

  return (
    <FilterCheckboxGroup
      name="filter_firm_type"
      label={t('label')}
      showLabel={showLabel}
      items={[
        { id: 'permanent', label: t('items.permanent') },
        { id: 'temporary', label: t('items.temporary') },
      ]}
    />
  )
}

function SalaryCheckboxes({ showLabel }) {
  const { t } = useTranslation('SalaryCheckboxes')

  return (
    <FilterCheckboxGroup
      name="filter_salary"
      label={t('label')}
      showLabel={showLabel}
      items={[
        { id: 'under_50k', label: t('items.under_50k') },
        { id: '50k_75k', label: t('items.50k_75k') },
        { id: '75k_100k', label: t('items.75k_100k') },
        { id: '100k_125k', label: t('items.100k_125k') },
        { id: '125k_plus', label: t('items.125k_plus') },
      ]}
    />
  )
}

function DesktopSearchButton() {
  const isDesktop = useIsDesktop()
  const { t } = useTranslation('DesktopSearchButton')

  if (!isDesktop) return null

  return (
    <div className="w-full pt-2">
      <PrimaryButton>{t('search_recruiters_now')}</PrimaryButton>
    </div>
  )
}

function MobileSearchButton() {
  const isDesktop = useIsDesktop()
  const { t } = useTranslation('MobileSearchButton')

  if (isDesktop) return null

  return (
    <div className="w-full pt-2">
      <PrimaryButton>{t('search_recruiters')}</PrimaryButton>
    </div>
  )
}
