import React, { useContext, createContext, useState } from 'react'
import { useIsDesktop, useIsMobile } from '../../hooks/useMediaQuery'
import usePersistState from '../../hooks/usePersistState'
import { ReminderProvider } from './ReminderDialog'

const DashboardContext = createContext()

export const useDashboard = () => {
  return useContext(DashboardContext)
}

export const DashboardProvider = ({
  userReminders,
  current_user,
  children,
  tracker_progress,
  tracker_time_period,
  quote,
  coach,
  calendar_events,
  orientation_call_completed,
  accolades,
}) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  const candidateId = current_user.tracker.candidate_id

  const { incompleteReminders } = userReminders

  const trackerProgress = tracker_progress
  const trackerTimePeriod = tracker_time_period
  const calendarEvents = calendar_events
  const orientationCallCompleted = orientation_call_completed

  const [showCoachInfoDialog, setShowCoachInfoDialog] = useState(false)

  const [onlyReminders, setOnlyReminders] = usePersistState(
    false,
    'onlyReminders',
  )

  const toggleShowCoachInfoDialog = () =>
    setShowCoachInfoDialog(!showCoachInfoDialog)

  const value = {
    showCoachInfoDialog,
    setShowCoachInfoDialog,
    toggleShowCoachInfoDialog,
    isMobile,
    isDesktop,
    candidateId,
    onlyReminders,
    setOnlyReminders,
    trackerProgress,
    quote,
    coach,
    calendarEvents,
    orientationCallCompleted,
    trackerTimePeriod,
    accolades,
  }

  const reminderValue = {
    candidateId,
    incompleteReminders,
  }

  return (
    <DashboardContext.Provider value={value}>
      <ReminderProvider {...reminderValue}>{children}</ReminderProvider>
    </DashboardContext.Provider>
  )
}
