import * as React from 'react'

import { useJobSearch } from './JobSearchProvider'
import Sanitize from '../Sanitize'
import { LinkedInButton, ApplyNowButton, ApplyWithIntooButton } from './buttons'
import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import IndeedLogo from './IndeedLogo'
import SavedJobButton from './SavedJobButton'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import CheckCircleIcon from './CheckCircleIcon'
import { CompanyImage } from './JobCard'

export default function JobDetails() {
  return (
    <div className="flex flex-col gap-2">
      <JobDetailsTop />
      <JobDetailsBottom />
    </div>
  )
}

export function JobDetailsTop() {
  const {
    selectedJob: job,
    debug,
    queryStatusMessage,
    isIntooItaly,
    showTalentguide,
    isDemo,
  } = useJobSearch()
  const { t } = useTranslation('JobDetails')
  const isMobile = useIsMobile()
  if (!job) return null

  return (
    <>
      <div className="absolute right-0 top-4 mt-[-0.75rem]">
        <SavedJobButton job={job} variant="icon" includeText />
      </div>
      <div className="ml-4 flex flex-row items-center gap-6">
        <div>
          <CompanyImage job={job} />
        </div>
        <div className="flex flex-col gap-1">
          <div className="relative flex flex-row justify-between">
            {!!job.posted_on_month_day && (
              <div className="text-xs">
                {t('posted')} {job.posted_on_month_day}
              </div>
            )}
          </div>

          {job?.job_bank_job && isIntooItaly && isDemo && (
            <div className="flex">
              <div
                className="rounded-md p-1.5 px-2.5 text-xs font-medium text-white"
                style={{ background: 'var(--theme-primary)' }}
              >
                {t('intoo_job_bank')}
              </div>
            </div>
          )}

          <div className="flex flex-col gap-1">
            <h2 className="text-base font-extrabold">{job.title}</h2>

            <div className="flex items-center text-sm">
              <LeaveSiteWarning url={job.url}>
                <a className="">{job.company_name}</a>
              </LeaveSiteWarning>
              <span className="mx-2">•</span>
              <div>{job.location}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-start gap-3 sm:gap-4">
        {job.job_bank_job ? (
          job.job_bank_job_applied ? (
            <AppliedWithIntoo />
          ) : (
            <ApplyWithIntooButton job={job} />
          )
        ) : (
          <ApplyNowButton job={job} compact={isMobile} />
        )}
        <LinkedInButton job={job} compact={isMobile} />
        <SavedJobButton job={job} compact={isMobile} />
      </div>

      {showTalentguide && (
        <LeaveSiteWarning
          url={`/orientation/talentguide/new?job_url=${job.url}&utm_campaign=send_vacancy`}
          className="ga-trackable flex items-center gap-2"
        >
          <a className="flex items-center">
            Send to
            <img
              src="https://www-yns-com.s3.amazonaws.com/images/talentguide_jobs_logo.webp"
              alt="Talentguide logo"
              className="ml-1 h-[30px]"
            />
          </a>
        </LeaveSiteWarning>
      )}

      {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
    </>
  )
}

export function JobDetailsBottom() {
  const { selectedJob: job } = useJobSearch()

  if (!job) return null
  const isIndeed = job.job_publisher_name === 'Indeed'

  return (
    <div className="text-base">
      <Sanitize html={job.description} addMarkup />
      {isIndeed && job?.job_bank_job && (
        <LeaveSiteWarning url={job.url}>
          <div className="mb-4 mt-16 flex flex-row gap-1 text-sm text-intoo-blue-medium">
            {`jobs by`} <IndeedLogo className="-mt-1" />
          </div>
        </LeaveSiteWarning>
      )}
    </div>
  )
}

export function AppliedWithIntoo() {
  const { t } = useTranslation('ApplyWithIntooButton')
  return (
    <div
      className="flex cursor-default items-center gap-0"
      title={t('applied_with_intoo')}
    >
      <CheckCircleIcon />
      <span
        className="text-sm font-medium"
        style={{ color: 'var(--theme-secondary)' }}
      >
        {t('applied')}
      </span>
    </div>
  )
}
