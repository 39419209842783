import React from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../lib/ui/select'
import { useTranslation } from '../../hooks/useTranslation'

const SetTimelineForm = ({ setNewTimeline }) => {
  const { t } = useTranslation()
  const timelineKeys = t('timeline_options')

  return (
    <div className="flex flex-col gap-4 px-4">
      <div>Please select your desired timeline:</div>
      <Select onValueChange={(v) => setNewTimeline(v)}>
        <SelectTrigger>
          <SelectValue placeholder="Select an option" />
        </SelectTrigger>
        <SelectContent>
          {Object.entries(timelineKeys).map(([key, value]) => (
            <SelectItem key={key} value={key}>
              {value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
export default SetTimelineForm
