import * as React from 'react'

import translations from '../locales.json'
import { BrowserRouter, useSearchParams } from 'react-router-dom'
import { TranslationProvider } from '../../shared/TranslationProvider'
import { CoachHeader } from '../CoachHeader'
import { Tabs, TabsContent, TabsList, TabsTrigger } from './CoachTabs'
import {
  DocMagnifyingGlass,
  Golf,
  ChatBubble,
  Clock,
  LightBulbOn,
  Phone,
} from 'iconoir-react'
import { LinkedInIcon } from '../LinkedInIcon'
import { Feedback } from './Feedback'
import {
  Carousel as PossibleCarousel,
  CarouselContent as PossibleCarouselContent,
  CarouselItem as PossibleCarouselItem,
} from '../../../lib/ui/carousel'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { ChatHistory } from './ChatHistory'

const tabs = ['resume', 'social', 'target', 'interview']

const getTabIndex = (value) => {
  return tabs.indexOf(value)
}
const getTabName = (index) => {
  return tabs[index]
}

function CoachFeedback({ feedback }) {
  const isDesktop = useIsDesktop()
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTab = searchParams.get('tab') || 'resume'
  const [currentTab, setCurrentTab] = React.useState(defaultTab)
  const currentTabIndex = getTabIndex(currentTab)
  const [api, setApi] = React.useState()
  const [slidesInView, setSlidesInView] = React.useState([])
  const { isFlex } = feedback
  const debug = false

  // Handle initial setup
  React.useEffect(() => {
    if (!api || isDesktop) return

    setSlidesInView(api.slidesInView())

    api.on('slidesInView', () => {
      setSlidesInView(api.slidesInView())
    })
  }, [api, isDesktop])

  // Handle sliding tabs, making sure the current tab is always in view
  React.useEffect(() => {
    if (!api || isDesktop) return

    if (!slidesInView.includes(currentTabIndex)) {
      if (currentTabIndex < slidesInView[0]) {
        setCurrentTab(getTabName(slidesInView[0]))
      } else {
        setCurrentTab(getTabName(slidesInView[slidesInView.length - 1]))
      }
    }
  }, [api, currentTabIndex, isDesktop, slidesInView])

  // Handle tab change, automatically sliding hidden tabs into view
  const handleTabChange = (newTabName) => {
    setSearchParams({ tab: newTabName })
    setCurrentTab(newTabName)

    if (!api || isDesktop) return

    const currentTabIsFirst = slidesInView[0] === getTabIndex(newTabName)
    if (currentTabIsFirst & api.canScrollPrev()) {
      api.scrollPrev()
    }

    const currentTabIsLast =
      slidesInView[slidesInView.length - 1] === getTabIndex(newTabName)
    if (currentTabIsLast & api.canScrollNext()) {
      api.scrollNext()
    }
  }

  const PassThrough = ({ children }) => <>{children}</>
  const Carousel = isDesktop ? PassThrough : PossibleCarousel
  const CarouselContent = isDesktop ? PassThrough : PossibleCarouselContent
  const CarouselItem = isDesktop ? PassThrough : PossibleCarouselItem

  const TextResume = isDesktop ? 'Resume Review' : 'Resume Review'
  const TextSocial = isDesktop ? 'Social Media Review' : 'Social Review'
  const TextTarget = isDesktop ? 'Target Company Research' : 'Target Research'
  const TextInterview = isDesktop ? 'Interview Review' : 'Interview Review'
  const TextChatHistory = isDesktop ? 'Chat History' : 'Chat History'
  const TextOrientation = isDesktop ? 'Orientation Call Review' : 'Orientation'
  const TextCareer = isDesktop
    ? 'Career Consultation Call Review'
    : 'Career Call'

  return (
    <div className="mx-2 my-12 flex flex-col gap-10 font-roboto sm:m-12">
      <div className="flex flex-col gap-6">
        {debug && (
          <div className="grid grid-cols-2">
            <div>currentTabIndex: {currentTabIndex}</div>
            <div>currentTab: {currentTab}</div>
            <div>slidesInView: {slidesInView.join(', ')}</div>
          </div>
        )}
        <CoachHeader
          title="Your Coach Feedback"
          description="Your coach's feedback will be shown below"
        />

        <Tabs
          defaultValue={defaultTab}
          value={currentTab}
          onValueChange={handleTabChange}
        >
          <TabsList>
            <Carousel
              setApi={setApi}
              opts={{
                align: 'start',
              }}
              className="w-full"
            >
              <CarouselContent className="items-end">
                <CarouselItem className="basis-1/3">
                  <TabsTrigger
                    className="w-[112px] sm:w-auto"
                    value="orientation"
                  >
                    <Phone className="text-intoo-green-coachcta" />
                    {TextOrientation}
                  </TabsTrigger>
                </CarouselItem>
                <CarouselItem className="basis-1/3">
                  <TabsTrigger className="w-[112px] sm:w-auto" value="career">
                    <LightBulbOn className="text-intoo-green-coachcta" />
                    {TextCareer}
                  </TabsTrigger>
                </CarouselItem>
                <CarouselItem className="basis-1/3">
                  <TabsTrigger className="w-[112px] sm:w-auto" value="resume">
                    <DocMagnifyingGlass className="text-intoo-green-coachcta" />
                    {TextResume}
                  </TabsTrigger>
                </CarouselItem>
                <CarouselItem className="basis-1/3">
                  <TabsTrigger
                    className="w-[112px] sm:w-auto"
                    value="social"
                    hide={isFlex}
                  >
                    <LinkedInIcon className="text-intoo-green-coachcta" />
                    {TextSocial}
                  </TabsTrigger>
                </CarouselItem>
                <CarouselItem className="basis-1/3">
                  <TabsTrigger
                    className="w-[112px] sm:w-auto"
                    value="target"
                    hide={isFlex}
                  >
                    <Golf className="text-intoo-green-coachcta" />
                    {TextTarget}
                  </TabsTrigger>
                </CarouselItem>
                <CarouselItem className="basis-1/3">
                  <TabsTrigger
                    className="w-[112px] sm:w-auto"
                    value="interview"
                    hide={isFlex}
                  >
                    <ChatBubble className="text-intoo-green-coachcta" />
                    {TextInterview}
                  </TabsTrigger>
                </CarouselItem>
                <CarouselItem className="basis-1/3">
                  <TabsTrigger className="w-[112px] sm:w-auto" value="history">
                    <Clock className="text-intoo-green-coachcta" />
                    {TextChatHistory}
                  </TabsTrigger>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          </TabsList>
          <TabsContent value="orientation">
            <Feedback
              title="Orientation Call Review"
              feedback={feedback}
              current="orientation"
            />
          </TabsContent>
          <TabsContent value="career">
            <Feedback
              title="Career Consultation Review"
              feedback={feedback}
              current="career"
            />
          </TabsContent>
          <TabsContent value="resume">
            <Feedback
              title="Resume Review"
              feedback={feedback}
              current="resume"
            />
          </TabsContent>
          <TabsContent value="social">
            <Feedback title="Social Media Review" feedback={null} />
          </TabsContent>
          <TabsContent value="target">
            <Feedback title="Target Company Research" feedback={null} />
          </TabsContent>
          <TabsContent value="interview">
            <Feedback title="Interview Review" feedback={null} />
          </TabsContent>
          <TabsContent value="history">
            <ChatHistory title="Chat History" feedback={feedback} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}

export default function CoachFeedbackWithProviders({ locale, ...props }) {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.coach_services.CoachFeedback"
    >
      <BrowserRouter>
        <CoachFeedback {...props} />
      </BrowserRouter>
    </TranslationProvider>
  )
}
