import * as React from 'react'
import { cn } from '../../lib/utils'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../lib/ui/form'
import { Textarea } from '../../lib/ui/textarea'

export function TextareaField({
  description,
  placeholder,
  name,
  label,
  control,
  textAreaClassName,
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea
              placeholder={placeholder}
              className={cn('resize-none', textAreaClassName)}
              {...field}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
