import React, { useEffect, useState } from 'react'
import { cn } from '../../lib/utils'
import LightBulbIcon from './Icons/LightBulb'

const Response = ({
  responseType,
  showResponse,
  response,
  alreadyTransitioned,
  className,
  source,
  showSourceFor,
  showCoachTip,
}) => {
  const [topLine, setTopLine] = useState()
  const [paragraph, setParagraph] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('')
  const [transform, setTransform] = useState('')

  useEffect(() => {
    if (!alreadyTransitioned) {
      setTransform('transform -translate-y-32')
    }
  }, [alreadyTransitioned])

  useEffect(() => {
    if (showResponse) {
      setTransform('')
    }
    if (response) {
      const responseArray = Array.isArray(response) ? response : [response]
      if (responseArray.length > 1) {
        setTopLine(responseArray[0])
        setParagraph(responseArray[1])
      } else {
        setTopLine()
        setParagraph(responseArray[0])
      }
      if (responseType !== 'noFeedback')
        setBackgroundColor(responseType == 0 ? 'bg-[#F3FFEB]' : 'bg-[#FCF4DA]')
    }
  }, [response, showResponse, responseType])

  return (
    <div
      className={cn(
        backgroundColor,
        transform,
        'mt-4 flex flex-col gap-3 rounded-[20px] border border-solid border-[#b9b9b924] p-4 duration-1000 sm:justify-center',
        className,
      )}
    >
      {topLine && (
        <div style={{ fontFamily: 'gotham_boldregular' }}>{topLine}</div>
      )}
      <div className="break-words">
        <div className="flex gap-2">
          {responseType === 'noFeedback' && (
            <div className="flex flex-col items-center justify-center">
              <LightBulbIcon />
            </div>
          )}
          {paragraph}
        </div>
        {source &&
          (showSourceFor === undefined ||
            (showSourceFor === 'yes_response' && responseType === 0) ||
            (showSourceFor === 'no_response' && responseType === 1)) && (
            <div className="mt-2 text-sm">
              *Source:{' '}
              <a href={source} target="_blank" rel="noopener noreferrer">
                {source}
              </a>
            </div>
          )}
        {responseType === 1 && showCoachTip && (
          <div className="mt-2 text-sm">
            <p>
              <strong>Coach Tip:</strong> {showCoachTip}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
export default Response
