import React from 'react'
import { cn } from '../../lib/utils'
export const BrainIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      stroke="currentColor"
      className={cn(className, 'size-6')}
    >
      <path
        d="M11.6667 23.3333C8.90525 23.3333 6.66666 25.5718 6.66666 28.3333C6.66666 31.0948 8.90525 33.3333 11.6667 33.3333C12.2511 33.3333 12.812 33.233 13.3333 33.0488"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.10653 26.0077C4.87383 24.9303 3.3334 22.645 3.3334 20C3.3334 17.9805 4.23128 16.1709 5.64963 14.9483"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70088 14.8137C5.25817 14.1518 5 13.356 5 12.5C5 10.1988 6.86548 8.33333 9.16667 8.33333C10.1049 8.33333 10.9706 8.64339 11.6671 9.16666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0628 9.27554C11.8087 8.73691 11.6667 8.13504 11.6667 7.49999C11.6667 5.19881 13.5322 3.33333 15.8333 3.33333C18.1345 3.33333 20 5.19881 20 7.49999V33.3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 33.3333C13.3333 35.1743 14.8257 36.6667 16.6667 36.6667C18.5077 36.6667 20 35.1743 20 33.3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 11.6667C20 14.4281 22.2385 16.6667 25 16.6667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3503 14.9483C35.7687 16.1709 36.6667 17.9805 36.6667 20C36.6667 21.1718 36.3643 22.273 35.8333 23.2298"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2992 14.8137C34.7418 14.1518 35 13.356 35 12.5C35 10.1988 33.1345 8.33333 30.8333 8.33333C29.8952 8.33333 29.0293 8.64339 28.333 9.16666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 7.5C20 5.19881 21.8655 3.33333 24.1667 3.33333C26.4678 3.33333 28.3333 5.19881 28.3333 7.5C28.3333 8.13505 28.1913 8.73691 27.9372 9.27555"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3333 36.6667C21.4923 36.6667 20 35.1743 20 33.3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.1667 34.1667L36.6667 36.6667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6667 30.8333C26.6667 33.1345 28.5322 35 30.8333 35C31.986 35 33.0292 34.532 33.7835 33.7757C34.5352 33.022 35 31.982 35 30.8333C35 28.5322 33.1345 26.6667 30.8333 26.6667C28.5322 26.6667 26.6667 28.5322 26.6667 30.8333Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PuzzleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
      />
    </svg>
  )
}

export const AylaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      stroke="currentColor"
      className="size-6"
    >
      <path
        d="M5 35L21.6667 18.3333M30 10L25.8333 14.1667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 3.33333L17.4088 7.59113L21.6667 9.16666L17.4088 10.7422L15.8333 15L14.2578 10.7422L10 9.16666L14.2578 7.59113L15.8333 3.33333Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M31.6667 16.6667L32.567 19.0997L35 20L32.567 20.9003L31.6667 23.3333L30.7663 20.9003L28.3333 20L30.7663 19.0997L31.6667 16.6667Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
