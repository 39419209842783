import { useTranslation } from '../../hooks/useTranslation'
import { useState, useEffect } from 'react'

export const useYesNoQuizScore = (content) => {
  const numAs = Object.values(content).filter((val) => val === 'a').length
  const score = Math.round((numAs / Object.keys(content).length) * 100)
  if (numAs <= 8) {
    return [1, numAs]
  } else if (numAs <= 14) {
    return [2, numAs]
  } else {
    return [3, score]
  }
}

export const useMultipleChoiceQuizScore = (
  content,
  questions,
  breakPoints,
  scoreType = 'percent',
) => {
  let tempNumCorrect = 0
  const contentKeys = Object.keys(content)
  contentKeys.forEach((key) => {
    const answerChoice = content[key]
    if (questions[key].options[answerChoice].feedback_key === 'yes_response') {
      tempNumCorrect += 1
    }
  })
  const numCorrect = tempNumCorrect
  const score =
    scoreType === 'percent'
      ? Math.round((numCorrect / contentKeys.length) * 100)
      : numCorrect

  for (let i = 0; i < breakPoints.length; i++) {
    if (numCorrect > breakPoints[i]) {
      return [i + 1, score]
    }
  }
  return [breakPoints.length + 1, score]

  // if (numCorrect > 7) {
  //   return [1, score]
  // } else if (numCorrect > 4) {
  //   return [2, score]
  // } else if (numCorrect > 2) {
  //   return [3, score]
  // } else {
  //   return [4, score]
  // }
}

export const useSpotSoftSkillQuizScore = (content, questions) => {
  let tempNumCorrect = 0
  const contentKeys = Object.keys(content)
  contentKeys.forEach((key) => {
    const answerChoice = content[key]
    if (questions[key].options[answerChoice].feedback_key === 'yes_response') {
      tempNumCorrect += 1
    }
  })
  const numCorrect = tempNumCorrect
  const score = Math.round((numCorrect / contentKeys.length) * 100)

  return score
}

export const useCalculateMultipleChoiceScore = (content, questions) => {
  let tempNumCorrect = 0
  const contentKeys = Object.keys(content)
  contentKeys.forEach((key) => {
    const answerChoice = content[key]
    if (questions[key].options[answerChoice].feedback_key === 'yes_response') {
      tempNumCorrect += 1
    }
  })
  const numCorrect = tempNumCorrect
  const score = Math.round((numCorrect / contentKeys.length) * 100)
  return [score, numCorrect]
}

export const useJobSearchStrategyKnowledgeScore = (
  trackContent,
  formattedQuizName,
) => {
  const { t } = useTranslation(formattedQuizName)

  const calculateScore = (content, questions) => {
    let tempNumCorrect = 0
    const contentKeys = Object.keys(content)

    contentKeys.forEach((key) => {
      const answerChoice = content[key]
      if (
        questions[key].options[answerChoice].feedback_key === 'yes_response'
      ) {
        tempNumCorrect += 1
      }
    })

    const numCorrect = tempNumCorrect

    return numCorrect
  }

  const getScoreSectionAndProficiency = (numCorrect) => {
    if (numCorrect === 15) return { section: 1, proficiency: 'expert' }
    if (numCorrect >= 12) return { section: 2, proficiency: 'advanced' }
    if (numCorrect >= 7) return { section: 3, proficiency: 'developing' }
    if (numCorrect >= 6) return { section: 4, proficiency: 'novice' }
    return { section: 4, proficiency: 'novice' }
  }

  const score = calculateScore(trackContent, t('questions'))
  const { section, proficiency } = getScoreSectionAndProficiency(score)

  return { score, section, proficiency, t }
}

export const useSelfAssessmentQuizScore = (content) => {
  const [feedbackKey, setFeedbackKey] = useState(null)
  const [selfAssessmentResponse, setSelfAssessmentResponse] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    const calculateScores = () => {
      const scoreCounts = {}

      for (const key of Object.values(content)) {
        scoreCounts[key] = (scoreCounts[key] || 0) + 1
      }

      const maxScore = Math.max(...Object.values(scoreCounts))
      const maxScoreKeys = Object.keys(scoreCounts).filter(
        (key) => scoreCounts[key] === maxScore,
      )

      if (maxScoreKeys.length === 1) {
        setFeedbackKey(maxScoreKeys[0])
        setSelfAssessmentResponse('clearResult')
      } else if (maxScoreKeys.length <= 3) {
        setFeedbackKey(maxScoreKeys)
        setSelfAssessmentResponse('splitResult')
      } else if (maxScoreKeys.length === 5) {
        setSelfAssessmentResponse('allSameScore')
      } else {
        setSelfAssessmentResponse('clearResult')
      }
    }

    calculateScores()
  }, [content, t])

  return {
    feedbackKey,
    selfAssessmentResponse,
  }
}
