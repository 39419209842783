import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RecruiterSearchProvider } from './RecruiterSearchProvider'
import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import ToolBar from './ToolBar'
import SearchResults from './SearchResults'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient()

export default function RecruiterSearchPage({
  search_results,
  total_results,
  total_pages,
  next_page,
  allow_debug,
  locale,
  industries,
  saved_recruiter_ids,
  location,
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider
        locale={locale}
        translations={translations}
        scope="javascript.components.job_search.RecruiterSearchPage"
      >
        <BrowserRouter>
          <RecruiterSearchProvider
            search_results={search_results}
            total_pages={total_pages}
            total_results={total_results}
            next_page={next_page}
            allow_debug={allow_debug}
            industries={industries}
            saved_recruiter_ids={saved_recruiter_ids}
            location={location}
          >
            <div className="flex flex-col border-l border-alto-200 pl-2 font-roboto">
              <div className="my-10 ml-4 mr-6 flex flex-col gap-8">
                <ToolBar />
                <SearchResults />
              </div>
            </div>
          </RecruiterSearchProvider>
        </BrowserRouter>
      </TranslationProvider>
    </QueryClientProvider>
  )
}
